<template>
  <div id="ItemAnalysis">
    <div :class="$store.state.lang == 'en' ? 'english' : ''">
      <div class="loading" v-show="loading">
        <div class="loading-content">
          <img src="https://newstore.vynior.com/close1%402x.png" alt="" class="loading-close" @click="loadingClose" />
          <el-progress :percentage="loadingProgress" :show-text="false" color="#fff" :stroke-width="4" class="loading-progress"></el-progress>
          <div class="loading-text">
            {{ loadingProgress >= 100 && analyzeStatus ? this.$t(`message.loading.Successsuanfa`) : this.$t(`message.loading.suanfa`) }}
          </div>
        </div>
      </div>
      <Header :info="info" :page_id="page_id"></Header>
      <!-- 选择项目\选择产品 -->
      <div class="header_bar">
        <!-- 第一行 -->
        <div class="bar_One">
          <div class="Page_title" @click="red">
            {{ $t(`message.itemAnalyse.Title`) }}
            <div class="line"></div>
          </div>

          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item :label="$t(`message.itemAnalyse.item`)" class="bar_One_text_1">
              <el-select v-model="formInline.product" :placeholder="$t(`message.itemAnalyse.select1`)">
                <el-option
                  v-for="(item, index) in program_name"
                  :key="index"
                  :label="item.project_name + '/' + item.category"
                  :value="item.project_name + '/' + item.category"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="$t(`message.itemAnalyse.products`)" class="bar_One_text_2">
              <el-select v-model="formInlines.product" :placeholder="$t(`message.itemAnalyse.select2`)">
                <el-option v-for="(item, index) in product_name" :key="index" :label="item.name" :value="item.name"> </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <div class="block">
            <el-tooltip class="item" :open-delay="500" effect="dark" placement="bottom" popper-class="back_ground">
              <div slot="content">
                {{ $t(`message.Tooltip.MarketSize`) }}<br />
                &nbsp; {{ $t(`message.Tooltip.MarketSizes`) }}
              </div>
              <img src="https://newstore.vynior.com/Subtract%402x.png" alt="" class="Pepole" />
            </el-tooltip>
            <span class="demonstration">{{ $t(`message.itemAnalyse.population`) }}</span>
            <el-cascader
              class="cascader"
              :placeholder="$t(`message.itemAnalyse.select`)"
              :options="options"
              :show-all-levels="false"
              :popper-append-to-body="false"
              :props="{ multiple: false }"
              clearable
              popper-class="hide"
              ref="market_cascader"
              v-model="onecode"
              @change="markethandleChange"></el-cascader>
            <!-- @change="mianCascader" -->
            <!-- {{onecode}} -->
          </div>
          <!-- cn -->
          <div class="button" v-show="$store.state.lang == 'cn'">
            <div class="fenxi" @click="req_Product">
              <img src="https://newstore.vynior.com/agains.png" alt="" />
            </div>
            <div class="port" @click="deriveIdeal">
              <el-tooltip placement="bottom" popper-class="back_grounds" :open-delay="500">
                <div slot="content">
                  点击按钮将导出当前页面选择的产品数据<br />
                  若导出项目全部产品结果<br />
                  请进入我的项目-已有项目进行导出。
                </div>
                <img src="https://newstore.vynior.com/2223s.png" alt="" />
              </el-tooltip>
            </div>
            <div class="compare" @click="goItem">
              <img src="https://newstore.vynior.com/ana1.png" alt="" />
            </div>
          </div>
          <!-- english -->
          <div class="button" v-show="$store.state.lang == 'en'">
            <div class="fenxi" @click="req_Product">
              <img src="https://newstore.vynior.com/laishistart.png" alt="" />
            </div>
            <div class="port" @click="deriveIdeal">
              <el-tooltip placement="bottom" popper-class="back_grounds">
                <div slot="content">
                  {{ $t(`message.Tooltip.Clicking`) }}<br />
                  {{ $t(`message.Tooltip.export`) }}<br />
                  {{ $t(`message.Tooltip.enter`) }}
                </div>
                <img src="https://newstore.vynior.com/daochuss.png" alt="" />
              </el-tooltip>
            </div>
            <div class="compare" @click="goItem">
              <img src="https://newstore.vynior.com/Group%2033282.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="flex_box">
        <!-- -------------------------------------------------------- -->
        <!-- 感观数据 -->
        <div class="FeelData">
          <!-- 标题头 -->
          <div class="FD_title">
            <div class="fd_title_one">
              <span class="fds_Title">{{ $t(`message.itemAnalyse.box1Title`) }}</span>
              <div class="block">
                <el-cascader
                  v-model="choose_name"
                  :placeholder="$t(`message.itemAnalyse.inputContent`)"
                  :options="$store.state.lang === 'en' ? options_search : ch_options_search"
                  :props="{ multiple: true }"
                  filterable
                  ref="cascader"
                  @change="handleChange">
                </el-cascader>
              </div>
              <div class="reset_img" v-show="$store.state.lang == 'cn'">
                <img @click="reser_" src="https://newstore.vynior.com/reset_s.png" alt="" />
              </div>
              <div class="reset_img" v-show="$store.state.lang == 'en'">
                <img @click="reser_" src="https://newstore.vynior.com/Group%2033284%402x.png" alt="" />
              </div>
            </div>

            <div class="select_important">
              <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item style="display: flex">
                  <el-tooltip class="itemss" :open-delay="500" placement="bottom" popper-class="back_grounds" close-delay="3000" trigger="hover">
                    <div slot="content" style="">
                      {{ $t(`message.Tooltip.Importance`) }}<br />
                      {{ $t(`message.Tooltip.Impact`) }}<br />{{ $t(`message.Tooltip.Hightest`) }}<br />{{ $t(`message.Tooltip.low`) }}
                    </div>

                    <span class="Importance">{{ $t(`message.itemAnalyse.Importance`) }}</span>
                  </el-tooltip>

                  <el-select v-model="important" placeholder="重要度" v-show="$store.state.lang == 'cn'">
                    <el-option v-for="item in important_arr" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                  <el-select v-model="important" placeholder="重要度" v-show="$store.state.lang == 'en'">
                    <el-option v-for="item in important_arrEN" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <!--  数据呈现 -->
          <div class="FD_data_area">
            <!-- 具体数据 -->
            <!-- 呈现产品的具体数据 -->
            <div class="FD_data_val" v-show="JSON.stringify(this.product_message) !== '{}'">
              <div
                class="fd_data_one"
                v-show="item.importtant == important || important == 6"
                :class="product_message.paneldata.length - 1 == index ? 'show_content' : ''"
                v-for="(item, index) in map_arrs"
                :key="index">
                <!-- 数据名称 -->
                <div class="FD_titleName">
                  <div class="FD_name">
                    <!-- 重要度icon -->
                    <img class="img_num" :src="img_arr[item.importtant]" alt="" />
                  </div>
                  <div class="FD_attribute">
                    <!--  名字-->
                    <span>{{ $store.state.lang == 'cn' ? item.ch_name : item.en_name }}</span>
                  </div>
                  <div class="FD_num">
                    <span> {{ Number(item.data).toFixed(1) }}</span>
                  </div>
                </div>

                <!-- 数据轴线 -->
                <div class="FD_Axis">
                  <div class="axis_shadow">
                    <!-- 进度条 -->

                    <!-- 产品默认 -->
                    <div
                      :style="{
                        width: `${(item.data / 15) * 100}%`,
                      }"
                      class="un_xg_1_3_1"></div>
                    <!--  -->
                    <img
                      src="@/assets/image/1.png"
                      :style="{
                        left: `${((item.data - 0.15) / 15) * 100}%`,
                      }" />
                    <span
                      class="dot0_num"
                      :style="{
                        left: `${((item.data - 0.2) / 15) * 100}%`,
                      }"
                      >{{ Number(item.data).toFixed(1) }}</span
                    >

                    <!-- 四个对应颜色数据-->
                    <div class="tttttt" v-for="(citem, cindex) in selectarr" :key="cindex">
                      <!-- 红 -->
                      <img
                        class="color_dot1"
                        @click="change_zindex1"
                        src="@/assets/image/2.png"
                        v-show="citem.colorsindex == 1"
                        :style="{
                          left: `${(citem.sort_panel[index] / 15) * 100}%`,
                        }" />
                      <!-- 绿 -->
                      <img
                        @click="change_zindex2"
                        class="color_dot2"
                        src="@/assets/image/3.png"
                        v-show="citem.colorsindex == 2"
                        :style="{
                          left: `${(citem.sort_panel[index] / 15) * 100}%`,
                        }" />
                      <!-- 蓝 -->
                      <img
                        class="color_dot3"
                        @click="change_zindex3"
                        src="@/assets/image/4.png"
                        v-show="citem.colorsindex == 3"
                        :style="{
                          left: `${(citem.sort_panel[index] / 15) * 100}%`,
                        }" />
                      <!-- 黄 -->
                      <img
                        class="color_dot4"
                        @click="change_zindex4"
                        src="@/assets/image/5.png"
                        v-show="citem.colorsindex == 4"
                        :style="{
                          left: `${(citem.sort_panel[index] / 15) * 100}%`,
                        }" />

                      <div
                        :class="computeddot(citem)"
                        @click.stop="change_zindex($event)"
                        :style="{
                          left: `${(citem.sort_panel[index] / 15) * 100 - 5.5}%`,
                        }">
                        <span class="span_text" @click="change_zindextext(cindex)">{{ citem.product_name }}</span>

                        <span :class="computeddotyuan(citem)">{{ Number(citem.sort_panel[index]).toFixed(1) }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="axios_val">
                    <span class="axios_min">0</span>
                    <span class="axios_max">15</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- 具体数据空盒子box -->
            <div class="FD_empty_data" v-show="(JSON.stringify(this.product_message) !== '{}') == false">
              <div class="fd_data_one" v-for="(item, index) in Productsed[product_index].panel_attributes_data" :key="index">
                <!-- 数据名称 -->
                <div class="FD_titleName">
                  <div class="FD_name">
                    <img class="img_num" src="https://newstore.vynior.com/empty_1.png" alt="" />
                  </div>
                  <div class="FD_attribute">
                    <span>{{ $t(`message.itemAnalyse.Sensorydata`) }}</span>
                  </div>
                  <div class="FD_empty">
                    <span>--</span>
                  </div>
                </div>

                <!-- 数据轴线 -->
                <div class="FD_Axis">
                  <div class="axis_shadow">
                    <!-- 进度条 -->

                    <!-- 产品默认 -->
                    <div
                      :style="{
                        width: `${(item / 15) * 100}%`,
                      }"
                      class="un_xg_1_3_1"
                      v-show="0"></div>
                    <!--  -->
                    <img
                      v-show="0"
                      src="@/assets/image/1.png"
                      :style="{
                        left: `${((item - 0.15) / 15) * 100}%`,
                      }" />
                    <span
                      v-show="0"
                      class="dot0_num"
                      :style="{
                        left: `${((item - 0.2) / 15) * 100}%`,
                      }"
                      >{{ item }}</span
                    >
                  </div>

                  <div class="axios_val">
                    <span class="axios_min">0</span>
                    <span class="axios_max">15</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -------------------------------------------------------- -->
        <!--  消费者喜好度预测-->
        <div class="LikeData">
          <!-- 标题/详情信息/图表 -->
          <div class="LD_forecast">
            <!-- 标题 -->
            <div class="LD_title">
              <span class="ld_Title">{{ $t(`message.itemAnalyse.box2Title`) }}</span>

              <span class="ld_little_title1">{{ $t(`message.itemAnalyse.explain`) }}</span>
              <img src="https://newstore.vynior.com/ana7.png" alt="" />
            </div>

            <!-- 置信区间说明 -->
            <div class="Ld_detai">
              <div class="ld_left">
                <div class="text">
                  <!-- 90%置信区间 -->
                  <!-- {{ $t(`message.itemAnalyse.section90`) }}&nbsp;&nbsp;{{ Number(overall90_1).toFixed(2) }}~{{ Number(overall90_2).toFixed(2) }} -->
                  {{ $t(`message.itemAnalyse.section90`) }}
                </div>
                <div class="text">
                  {{ $t(`message.itemAnalyse.section95`) }}{{ Number(overall95_1).toFixed(2) }}~{{ Number(overall95_2).toFixed(2) }}
                </div>
              </div>
              <!-- <div class="ld_left" v-show="product_message!=''">
              <div class="text">95%置信区间:&nbsp;&nbsp;{{ product_message.overall_95[0].toFixed(2) }}~{{ product_message.overall_95[1].toFixed(2) }}</div>
              <div class="text">90%置信区间:&nbsp;&nbsp;{{ product_message.overall_90[0].toFixed(2) }}~{{ product_message.overall_90[1].toFixed(2) }}</div>
            </div> -->

              <div class="ld_right">
                <div class="ld_right_one">
                  <img src="https://newstore.vynior.com/ana8.png" alt="" />
                  <span class="Ld_like_value">{{ $t(`message.itemAnalyse.Predicted`) }}</span>
                </div>
                <div class="ld_right_two">
                  <img src="https://newstore.vynior.com/ana9.png" alt="" class="Ld_like_img" />
                  <el-tooltip class="item" :open-delay="500" effect="dark" :content="'content'" placement="top" popper-class="back_ground">
                    <div slot="content">{{ $t(`message.Tooltip.Accuracy`) }}<br />{{ $t(`message.Tooltip.higher`) }}</div>
                    <span class="Ld_like_value">{{ $t(`message.itemAnalyse.percentage`) }}</span>
                  </el-tooltip>
                </div>
              </div>
              <div class="Ld_detai_one" v-show="0">
                <span>95%置信区间-{{ Products[0].overall_95[0] }}~{{ Products[0].overall_95[1] }} </span>
                <img src="https://newstore.vynior.com/ana8.png" alt="" />
                <span class="Ld_like_value">整体喜好度预测值</span>
              </div>
              <div class="Ld_detai_two" v-show="0">
                <span> 90%置信区间-{{ Products[0].overall_90[0] }}~{{ Products[0].overall_90[1] }} </span>
                <img src="https://newstore.vynior.com/ana9.png" alt="" class="Ld_like_img" />
                <el-tooltip class="item" :open-delay="500" effect="dark" content="预测精准度释义" placement="bottom" popper-class="back_grounds">
                  <span class="Ld_like_value">预测精准度百分比</span>
                </el-tooltip>
              </div>
            </div>
            <!-- icon标题 -->
            <div class="icon_title">
              <div class="icon_img">
                <img src="https://newstore.vynior.com/iconss.png" />
              </div>
              <div class="icon_text">
                {{ $t(`message.itemAnalyse.Overall`) }}
                <!-- 切换 SPF < 50 -->
                <!-- <img v-show="showCut == 0 && change_bar_num == 1" class="img1" src="https://newstore.vynior.com/Group%2033738%402x.png" alt="" />
                <img
                  v-show="showCut == 0 && change_bar_num == 1"
                  @click="showCut = 2"
                  class="img_click"
                  src="https://newstore.vynior.com/16%EF%BC%8A16%EF%BC%8F%E6%8F%8F%E8%BE%B9%EF%BC%8F%E8%BF%94%E5%9B%9E%402x.png"
                  alt="" /> -->

                <!--  切换 ALL -->
                <!-- <img
                  v-show="showCut == 2 && change_bar_num == 1"
                  @click="showCut = 0"
                  class="img_click3"
                  src="https://newstore.vynior.com/16%EF%BC%8A16%EF%BC%8F%E6%8F%8F%E8%BE%B9%EF%BC%8F%E8%BF%94%E5%9B%9E1%402x.png"
                  alt="" />
                <img v-show="showCut == 2 && change_bar_num == 1" class="img3" src="https://newstore.vynior.com/xy50.png" alt="" />
                <img
                  v-show="showCut == 2 && change_bar_num == 1"
                  @click="showCut = 3"
                  class="img_click4"
                  src="https://newstore.vynior.com/16%EF%BC%8A16%EF%BC%8F%E6%8F%8F%E8%BE%B9%EF%BC%8F%E8%BF%94%E5%9B%9E%402x.png"
                  alt="" /> -->

                <!-- 切换 SPF >= 50 -->
                <!-- <img v-show="showCut == 3 && change_bar_num == 1" class="img2" src="https://newstore.vynior.com/dy50.png" alt="" />
                <img
                  v-show="showCut == 3 && change_bar_num == 1"
                  @click="showCut = 2"
                  class="img_click2"
                  src="https://newstore.vynior.com/16%EF%BC%8A16%EF%BC%8F%E6%8F%8F%E8%BE%B9%EF%BC%8F%E8%BF%94%E5%9B%9E1%402x.png"
                  alt="" /> -->

                <el-select v-model="likevalue" placeholder="请选择" @change="likeChange" v-show="change_bar_num == 1">
                  <el-option v-for="item in likeoptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </div>

              <div class="bar_tu" :class="change_bar_num == 1 ? 'bar_tus' : ''" @click="change_bar">{{ $t(`message.itemAnalyse.barChat`) }}</div>
              <div class="bar_s" :class="change_num == 1 ? 'bar_ss' : ''" @click="change_">{{ $t(`message.itemAnalyse.Rainbow`) }}</div>
            </div>
            <!-- echart图形 彩虹图 -->
            <div class="main_bar_fore" v-show="JSON.stringify(this.product_message) !== '{}' && bar_">
              <div class="one_line" :style="{ left: one[0] }">
                <div class="one_line_box_un">
                  <div class="text">
                    P20<span class="text_konw" @click="changhelpDialogRole">{{ $t(`message.itemAnalyse.konw`) }}</span>
                  </div>
                  <div class="loding-box">
                    <div class="img"></div>
                    <div class="num">{{ Number(s_f[0]).toFixed(2) }}</div>
                  </div>
                </div>
                <div class="one_line_box">
                  <img src="https://newstore.vynior.com/purpole_qiu.png" alt="" />
                  <div class="dashed"></div>
                </div>
              </div>
              <div class="one_line" :style="{ left: one[1] }">
                <div class="one_line_box_un">
                  <div class="text">
                    P40<span class="text_konw" @click="changhelpDialogRole">{{ $t(`message.itemAnalyse.konw`) }}</span>
                  </div>
                  <div class="loding-box">
                    <div class="img"></div>
                    <div class="num">{{ Number(s_f[1]).toFixed(2) }}</div>
                  </div>
                </div>
                <div class="one_line_box">
                  <img src="https://newstore.vynior.com/purpole_qiu.png" alt="" />
                  <div class="dashed"></div>
                </div>
              </div>
              <div class="one_line" :style="{ left: one[2] }">
                <div class="one_line_box_un">
                  <div class="text">
                    P60<span class="text_konw" @click="changhelpDialogRole">{{ $t(`message.itemAnalyse.konw`) }}</span>
                  </div>
                  <div class="loding-box">
                    <div class="img"></div>
                    <div class="num">{{ Number(s_f[2]).toFixed(2) }}</div>
                  </div>
                </div>
                <div class="one_line_box">
                  <img src="https://newstore.vynior.com/purpole_qiu.png" alt="" />
                  <div class="dashed"></div>
                </div>
              </div>
              <div class="one_line" :style="{ left: one[3] }">
                <div class="one_line_box_un">
                  <div class="text">
                    P80<span class="text_konw" @click="changhelpDialogRole">{{ $t(`message.itemAnalyse.konw`) }}</span>
                  </div>
                  <div class="loding-box">
                    <div class="img"></div>
                    <div class="num">{{ Number(s_f[3]).toFixed(2) }}</div>
                  </div>
                </div>
                <div class="one_line_box">
                  <img src="https://newstore.vynior.com/purpole_qiu.png" alt="" />
                  <div class="dashed"></div>
                </div>
              </div>
            </div>

            <!-- 帮助中心 -->
            <!-- 帮助中心 -->
            <HelpCenter :helpDialogRoles="helpDialogRole" v-if="helpDialogRole" @unloding1="unloding1"></HelpCenter>

            <!-- 柱状图 -->
            <div id="main_bar" v-show="bar_ == 1"></div>
            <!-- 空柱状图 -->
            <div id="main_bar_empty" v-show="bar_ == 2"></div>
            <!-- 彩虹图 -->
            <div id="main" v-show="suanfa_empty == 0"></div>
            <!-- 空彩虹图 -->
            <div id="main_empty" v-show="suanfa_emptys == 1"></div>

            <div class="echarts_data" v-show="suanfa_empty == 0">
              <p class="echarts_data_val">
                {{ Number(product_message.overall_data).toFixed(2) }}
              </p>

              <p class="precise">
                <el-tooltip class="item" :open-delay="500" effect="dark" placement="bottom" popper-class="back_ground">
                  <div slot="content">
                    {{ $t(`message.Tooltip.Accuracy`) }}<br />
                    &nbsp; {{ $t(`message.Tooltip.MarketSizes`) }}
                  </div>

                  <img src="https://newstore.vynior.com/Subtract%402x.png" alt="" class="Pepole" />
                </el-tooltip>
                {{ $t(`message.itemAnalyse.accuracy`) }}:&nbsp;94%
              </p>
            </div>
            <!-- 图形空数据呈现 -->
            <div class="echarts_empty_data" v-show="suanfa_emptys == 1">
              <p class="echarts_data_val">--</p>
              <p class="precise">{{ $t(`message.itemAnalyse.accuracy`) }}:--</p>
            </div>
          </div>
          <!-- 关注指标 -->
          <div class="LD_focus_index">
            <!-- 标题 -->
            <div class="LD_focus_title">
              <img src="https://newstore.vynior.com/ana11.png" alt="" />
              <!-- <span>{{ this.comprehensive_name }}</span> -->
              <span>{{ $t(`message.itemAnalyse.OverallAttributes`) }}</span>
              <img class="ld_choose" v-show="0" src="https://newstore.vynior.com/ana12.png" alt="" />
            </div>

            <!-- 关注的五个指标 -->
            <div class="LD_focus_data" v-show="JSON.stringify(this.product_message) !== '{}'">
              <div class="ld_data_one" v-for="(ind, index) in product_message.compre_attributes" :key="index">
                <div class="ld_score">
                  <div class="num_b">
                    <img src="https://newstore.vynior.com/ana13.png" alt="" />
                  </div>

                  <span>{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <el-tooltip class="item" effect="dark" placement="top" :open-delay="500" popper-class="back_grounds">
                    <span class="ld_name">{{ $store.state.lang == 'en' ? ind.attributes_name_en : ind.attributes_name_cn }}</span>
                    <span slot="content">{{ $store.state.lang == 'en' ? ind.attributes_name_en : ind.attributes_name_cn }}</span>
                  </el-tooltip>
                  <span class="ld_num">{{ Number(ind.attributes_value).toFixed(2) }}</span>
                </div>
                <!-- <div class="ld_percentage">
                  <span>{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">{{ ind.attributes_precision * 100 }}%</span>
                </div> -->
              </div>
              <!-- 不满6个(xian) -->
              <div class="ld_data_ones" v-for="(ins, ind) in Cha" :key="ind">
                <div class="ld_score">
                  <img src="https://newstore.vynior.com/ana13.png" alt="" />
                  <span>{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <span class="ld_name">{{ $t(`message.itemAnalyse.null`) }}</span>
                  <span class="ld_num">--</span>
                </div>
                <!-- <div class="ld_percentage">
                  <span>{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">--</span>
                </div> -->
              </div>
            </div>

            <!-- 关注的五个空指标---empty -->
            <div class="LD_empty_data" v-show="(JSON.stringify(this.product_message) !== '{}') == false">
              <div class="ld_data_one" v-for="(ind, index) in Products[0].overalln_5" :key="index">
                <div class="ld_score">
                  <img src="https://newstore.vynior.com/ana13.png" alt="" />
                  <span>{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <span class="ld_name">{{ $t(`message.itemAnalyse.null`) }}</span>
                  <span class="ld_num">--</span>
                </div>
                <!-- <div class="ld_percentage">
                  <span>{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">--</span>
                </div> -->
              </div>
            </div>
          </div>
          <!-- 其他指标 -->
          <div class="LD_other_index">
            <div class="ld_other_title">
              <img src="https://newstore.vynior.com/ana14.png" alt="" />
              <span>{{ $t(`message.itemAnalyse.SpecifiaAttributes`) }}</span>
            </div>
            <!-- 其他指标正常数据 -->
            <div class="ld_other_dataArea" v-show="JSON.stringify(this.product_message) !== '{}'">
              <!-- 接收数据展示个数 -->
              <div
                class="ld_other_data"
                :class="[index > 2 ? 'change_position' : '', index > 5 ? 'clearMargion' : '']"
                v-for="(item, index) in product_message.charac_attributes"
                :key="index">
                <div class="ld_score">
                  <div class="num_b">
                    <img src="https://newstore.vynior.com/ana13.png" alt="" />
                  </div>

                  <span class="like_num">{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <el-tooltip class="item" effect="dark" placement="top" :open-delay="500" popper-class="back_grounds">
                    <span class="ld_name">{{ $store.state.lang == 'en' ? item.attributes_name_en : item.attributes_name_cn }}</span>
                    <span slot="content">{{ $store.state.lang == 'en' ? item.attributes_name_en : item.attributes_name_cn }}</span>
                  </el-tooltip>
                  <span class="ld_num">{{ Number(item.attributes_value).toFixed(2) }}</span>
                </div>

                <div class="ld_percentage">
                  <!-- <span class="ld_per_text">{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">{{ item.attributes_precision * 100 }}%</span> -->
                </div>
              </div>
              <!-- 补0个数 -->
              <div
                class="ld_other_datas"
                v-for="(item, index) in Chas"
                :key="index"
                :class="[
                  Chas <= 3 ? 'clearMargion' : '',
                  Chas > 3 && index == Chas - 1 ? 'clearMargion' : '',
                  Chas > 3 && index == Chas - 2 ? 'clearMargion' : '',
                  Chas > 3 && index == Chas - 3 ? 'clearMargion' : '',
                ]">
                <div class="ld_score">
                  <img src="https://newstore.vynior.com/ana13.png" alt="" />
                  <span>{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <span class="ld_name">{{ $t(`message.itemAnalyse.null`) }}</span>
                  <span class="ld_num">--</span>
                </div>

                <!-- <div class="ld_percentage">
                  <span>{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">--</span>
                </div> -->
              </div>
            </div>

            <!-- 其他指标空数据 -->
            <div class="ld_other_dataArea_empty" v-show="(JSON.stringify(this.product_message) !== '{}') == false">
              <div class="ld_other_data" v-for="(item, index) in prediciton" :key="index">
                <div class="ld_score">
                  <img :src="item.imgurl" alt="" />
                  <span>{{ $t(`message.itemAnalyse.predicted`) }}</span>
                </div>
                <div class="ld_character">
                  <span class="ld_name">{{ $t(`message.itemAnalyse.null`) }}</span>
                  <span class="ld_num">--</span>
                </div>

                <!-- <div class="ld_percentage">
                  <span>{{ $t(`message.itemAnalyse.accuracy`) }}</span>
                  <span class="ld_percent">--</span>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------------------------------------------- -->
        <!-- 选择对比产品 -->
        <div class="ChooseCompare">
          <!-- 大标题 -->
          <div class="CC_title">
            <img src="https://newstore.vynior.com/icon2.png" alt="" />
            <span class="title">{{ $t(`message.itemAnalyse.box3Title`) }}</span>
            <!-- <el-tooltip
            class="item"
            effect="dark"
            content="最多可选择总数
             不超过4个的对比产品"
          >
            <img src="https://newstore.vynior.com/ana3.png" class="warn" />
          </el-tooltip> -->

            <span class="cc_tip">{{ $t(`message.itemAnalyse.maximum`) }}</span>
            <img src="https://newstore.vynior.com/ana5.png" class="reset" @click="clear_choose" v-show="$store.state.lang == 'cn'" />
            <img src=" https://newstore.vynior.com/resetsRS.png" class="reset" @click="clear_choose" v-show="$store.state.lang == 'en'" />
          </div>
          <!-- 推荐优化方案 box-->

          <!-- 推荐优化方案 -->
          <div class="CC_title_One">
            <div class="img">
              <img src="https://newstore.vynior.com/ana4.png" alt="" />
            </div>
            <div class="title">
              <span>{{ $t(`message.itemAnalyse.Recommended`) }}</span>
            </div>
            <div class="add">
              <img src="https://newstore.vynior.com/Frame%201284%402x.png" alt="" />
            </div>
            <el-tooltip :open-delay="500" placement="top" popper-class="back_grounds">
              <div class="addTitle" @click="addProgram">
                <span>{{ $t(`message.itemAnalyse.add`) }}</span>
              </div>

              <p slot="content">{{ $t(`message.itemAnalyse.cilckProject`) }}</p>
              <p slot="content">{{ $t(`message.itemAnalyse.cilckProject2`) }}</p>
            </el-tooltip>
          </div>
          <div v-show="JSON.stringify(this.product_message) !== '{}'" class="recommend_box">
            <!-- 产品名称/喜好度/颜色/光泽度/.... -->
            <div class="CC_Content_name">
              <el-tooltip :open-delay="500" placement="top" :content="$t(`message.itemAnalyse.liking`)" popper-class="back_grounds">
                <div class="name_two">{{ $t(`message.itemAnalyse.liking`) }}</div>
              </el-tooltip>

              <div v-for="plan in tj_paln_name" :key="plan.title" :class="plan.className" show-overflow-tooltip>
                <el-tooltip :open-delay="500" placement="top" :content="plan.title" popper-class="back_grounds">
                  <span style="font-family: HarmonyOS Sans SC-Medium">{{ plan.title }}</span>
                </el-tooltip>
              </div>
              <!-- <div class="name_three">产品外观</div>
            <div class="name_fore">使用后肤感</div>
            <div class="name_five">质地</div> -->
            </div>
            <!-- 推荐产品罗列 -->

            <div
              :class="[
                click_class[0] === index
                  ? remark_class[1]
                  : click_class[1] === index
                  ? remark_class[2]
                  : click_class[2] === index
                  ? remark_class[3]
                  : click_class[3] === index
                  ? remark_class[4]
                  : remark_class[0],
              ]"
              v-for="(item, index) in recommend"
              :key="index"
              @click.stop="throttledClick(index, item)">
              <!-- 简化计算方式 待优化 -->
              <div class="cover"></div>
              <el-checkbox v-model="history_click[index]"></el-checkbox>
              <span class="name">{{ $t(`message.itemAnalyse.optimal`) }}{{ index == 0 ? '1' : index == 1 ? '2' : index == 2 ? '3' : '' }}</span>
              <span class="like">{{ Number(item.overall_data).toFixed(2) }}</span>
              <span class="quality">
                {{ Number(item.appear_score).toFixed(2) }}
              </span>
              <span class="gloss">
                {{ Number(item.skin_feel_score).toFixed(2) }}
              </span>
              <span class="absorb">
                {{ Number(item.quality_score).toFixed(2) }}
              </span>
              <!-- <span class="smell">{{ item.scent_score.toFixed(2) }}</span> -->
            </div>
          </div>

          <!-- 推荐优化方案的空数据盒子 -->
          <div class="cc_empty_box" v-show="(JSON.stringify(this.product_message) !== '{}') == false">
            <div class="empty_img">
              <img src="https://newstore.vynior.com/empty11.png" alt="" />
              <span>{{ $t(`message.itemAnalyse.Norecommended`) }}</span>
            </div>
          </div>

          <!-- 历史产品选择 -->
          <div class="CC_History_title">
            <img src="https://newstore.vynior.com/ana6.png" />
            <span class="cc_history">{{ $t(`message.itemAnalyse.Historical`) }}</span>
            <div class="allin">
              <span class="allIN" v-show="$store.state.lang == 'cn'"
                >{{ history_name }}
                <div class="more_box">
                  <div class="more_choose" v-show="$store.state.lang == 'cn'">
                    <span class="all" @click="history_name = '全部'">全部</span>
                    <span @click="history_name = '市售产品'">市售产品</span>
                    <span @click="history_name = '开发原型'">开发原型</span>
                    <span @click="history_name = '竞争品牌'">竞争品牌</span>
                  </div>
                </div>
              </span>
              <span class="allIN" v-show="$store.state.lang == 'en'"
                >{{ history_name }}
                <div class="more_box">
                  <div class="more_choose">
                    <span class="all" @click="history_name = 'All'">All</span>
                    <span @click="history_name = 'In-market Product'">In-market Product</span>
                    <span @click="history_name = 'Prototype'">Prototype</span>
                    <span @click="history_name = 'Benchmark'">Benchmark</span>
                  </div>
                </div>
              </span>
            </div>
          </div>

          <!-- 历史产品选择内容标题 -->
          <div class="CC_History_title_name">
            <el-tooltip :open-delay="500" placement="top" :content="$t(`message.itemAnalyse.createTime`)" popper-class="back_grounds">
              <span>{{ $t(`message.itemAnalyse.createTime`) }}</span>
            </el-tooltip>
            <img src="https://newstore.vynior.com/hthj8.png" alt="" @click="sort" />
            <el-tooltip :open-delay="500" placement="top" :content="$t(`message.itemAnalyse.number`)" popper-class="back_grounds">
              <span class="cc_id">{{ $t(`message.itemAnalyse.number`) }}</span>
            </el-tooltip>
            <el-tooltip :open-delay="500" placement="top" :content="$t(`message.itemAnalyse.ProductNume`)" popper-class="back_grounds">
              <span class="cc_name">{{ $t(`message.itemAnalyse.ProductNume`) }}</span>
            </el-tooltip>
            <el-tooltip :open-delay="500" placement="top" :content="$t(`message.itemAnalyse.ocerallPredicted`)" popper-class="back_grounds">
              <span class="cc_like">{{ $t(`message.itemAnalyse.ocerallPredicted`) }}</span>
            </el-tooltip>
          </div>
          <!-- 历史选择数据 -->
          <div class="data_box" v-show="JSON.stringify(this.product_message) !== '{}'">
            <div
              :class="[
                history[index].colorsindex == 1
                  ? remarks_class[1]
                  : history[index].colorsindex == 2
                  ? remarks_class[2]
                  : history[index].colorsindex == 3
                  ? remarks_class[3]
                  : history[index].colorsindex == 4
                  ? remarks_class[4]
                  : remarks_class[0],
              ]"
              v-for="(item, index) in history"
              :key="index"
              @click.stop="throttledClick(index + 3, item)">
              <div class="cover"></div>
              <el-checkbox v-model="history_click[index + 3]"></el-checkbox>
              <span class="history_data">{{ item.createdtime }}</span>
              <span class="history_id">{{ item.Program_name }}</span>
              <el-tooltip :open-delay="500" class="item" effect="dark" :content="item.product_name" placement="top" popper-class="back_grounds">
                <span class="history_name">{{ item.product_name }}</span>
              </el-tooltip>
              <span class="history_like">{{ Number(item.overall_data).toFixed(2) }}</span>
            </div>
          </div>

          <!-- 空的历史数据 -->
          <div class="history_empty" v-show="(JSON.stringify(this.product_message) !== '{}') == false">
            <img src="https://newstore.vynior.com/empty_h.png" alt="" />

            <span>{{ $t(`message.itemAnalyse.NoHistorical`) }}</span>
          </div>
        </div>
      </div>
    </div>
    <H :helpDialogRoles="helpDialogRole" :signName="signName" v-if="helpDialogRole" @unloding1="unloding1"></H>

    <!-- 选择男的提示 -->

    <div class="sex_loding" v-show="showSexLoding">
      <div class="sex_main">
        <div class="sex_top">
          <div class="top_text">{{ $t(`message.itemAnalyse.Note`) }}</div>
          <img class="top_img" @click="showSexLoding = false" src="https://newstore.vynior.com/xxx.png" alt="" />
          <div></div>
        </div>
        <div class="sex_body">
          <div class="body_img"><img src="https://newstore.vynior.com/gth.png" alt="" /></div>
          <span class="body_text">{{ $t(`message.itemAnalyse.Population`) }}</span>
        </div>
        <div class="sex_btn">
          <div class="un">
            <span class="un_text" @click="showSexLoding = false">{{ $t(`message.Message.Cancel`) }}</span>
          </div>
          <div class="ok">
            <span class="ok_text" @click="showSexLoding = false">{{ $t(`message.Message.OK`) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import H from '@/components/HelpCenters.vue';
import getFilename from '../../utils/getFilename';
// import * as echarts from "echarts";
import Header from '@/components/Header';
import { HttpClient } from '@/utils/http';
import { message } from '@/utils/resetMessage';

// import { duration } from 'moment';
export default {
  name: 'ItemAnalysis',
  data() {
    return {
      lastClickTime: 0,
      Anay: [], //分析记录次数
      signName: 'ItemAnalysis',
      importantArr: [],
      maps: [],
      showSexLoding: false, //性别的弹窗
      dataMain: [], //全部数据
      Chas: 0, //其他指标补0个数
      Cha: 0, //关注指标补0个数
      helpDialogRole: false, //帮助中心的显示与隐藏
      currentHelp: 1, //帮助中心显示百分线
      s_f: [3.57, 3.78, 3.93, 4.11], //四分线数值
      newl: [], //选中的细分市场
      timer: null,
      analyzeStatus: true,
      loadingProgress: 0, // 算法进度
      info: 2,
      sexnum: 0,
      choose_products: [], //选择的对比产品的id
      likeoptions: [
        {
          //下拉框的数据
          value: 'ALL SPF',
          label: 'ALL SPF',
        },
        {
          value: 'SPF≥50',
          label: 'SPF≥50',
          disabled: true,
        },
        {
          value: 'SPF<50',
          label: 'SPF<50',
        },
        {
          value: '物理防晒',
          label: '物理防晒',
        },
        {
          value: '化学防晒',
          label: '化学防晒',
        },
        {
          value: '物化结合',
          label: '物化结合',
        },
      ],
      likevalue: '',
      one: ['61.5%', '64.25%', '71.75%', '75%'], //四分线位置
      //细分市场

      sement_sum2: ['', '', '', ''], //细分市场勾选数值
      sement_sum: [['无', 'NA']], //细分市场勾选数值
      sement_sum_show: [], //展示分析单品的tooltip
      segment: ['00000', '000', '00', '00'],
      segment_1: [0, 0, 0, 0, 0],
      segment_2: [0, 0, 0],
      segment_3: [0, 0],
      segment_4: [0, 0],
      // showCut:0,  //控制切换
      // segment: [-1, -1, -1, -1, -1],
      showCut: 0, //控制切换
      bar_name: ['1', '', '', '', ''], //柱状图的图例名字
      overall95_1: 0.0,
      overall95_2: 0.0,
      overall90_1: 0.0,
      overall90_2: 0.0,
      change_num: 1, //雷达图和彩虹图切换
      change_bar_num: 0, //雷达图和彩虹图切换样式
      caihong_: 0,
      bar_: 0,
      market: ['无', 'NA'],
      err_message: '',
      loading: false,
      wait_1: '',
      suanfa_empty: 1, //感官属性空状态控制
      suanfa_emptys: 1, //感官属性空数据展示
      oldHis: [], //存储原始的历史推荐产品
      history_name: '全部',
      sort_arr: false,
      map_arr: [],
      map_arrs: [],
      map_arrss: [],
      trans_name: '', //传递的数据名字
      choose_name: [],
      options_arr: [], //感官属性选择的属性下标
      important_arr: [
        {
          label: '全部',
          value: '6',
        },
        {
          label: '5',
          value: '0',
        },
        {
          label: '4',
          value: '1',
        },
        {
          label: '3',
          value: '2',
        },
        {
          label: '2',
          value: '3',
        },
        {
          label: '1',
          value: '4',
        },
      ],
      important_arrEN: [
        {
          label: 'All',
          value: '6',
        },
        {
          label: '5',
          value: '0',
        },
        {
          label: '4',
          value: '1',
        },
        {
          label: '3',
          value: '2',
        },
        {
          label: '2',
          value: '3',
        },
        {
          label: '1',
          value: '4',
        },
      ],
      count_arr: [], //合并数组【{重要度，感观数据名称}，{}】
      // sort_arr: [], //排序好的数组
      history_click: [], //多选框绑定选中未选中状态
      attribute_view: [], //重要度值
      attribute_data: [], //存储感官属性数据
      onecode: '',
      num: 1,
      historymaeket: true,
      history1: [],
      zindex: 1, //存放z-index的值
      history: [], //存放历史数据
      echarts_data: ['', '', '', '', ''], //存储彩虹图的数据,渲染.
      echarts_name: ['1', '2', '3', '4', '5'], //存储彩虹图的data_name
      product_message: {}, //接受产品的具体信息
      product_id: '', //接收选择项目的id
      choose_product: '', //存储 选择的产品
      add_checked: { checked: '', colorsindex: 0 },
      recommend_data: [], //存储推荐优化方案的数据
      recommend: [], //存储推荐优化方案的数据
      project_id: '637d8211c058dc6e7b98a523', //存储项目id
      choose_pj_name: '', //存储选中项目的名字
      choose_xm_name: '', //存储选中项目的名字
      product_name: [], //存储选择项目的产品
      program_name: [], //存储项目名称
      history_cancel: 0, //控制历史数据显示隐藏
      cheched: 'false',
      important: '6',

      // 暂存数组_name
      index_item: [],
      // 跳转传值
      page_id: '/itemanalysis',
      // 彩虹图联动数据
      echarts_index: [0, 0, 0, 0, 0],
      product_index: '0',
      product_new: [],
      // 感官数据搜索
      options_search: [], //感官属性的数据
      ch_options_search: [], //感官属性的数据
      // 细分市场
      options_cn: [],
      options_en: [],
      options: [
        {
          value: '年龄段',
          label: '年龄段',
          children: [
            {
              value: '18-25',
              label: '18-25',
            },
            {
              value: '26-35',
              label: '26-35',
            },
            {
              value: '36-45',
              label: '36-45',
            },
            {
              value: '18-35',
              label: '18-35',
            },
            {
              value: '26-45',
              label: '26-45',
            },
          ],
        },
        {
          value: '肤质',
          label: '肤质',
          children: [
            {
              value: '油性/混油',
              label: '油性/混油',
            },
            {
              value: '中性',
              label: '中性',
            },
            {
              value: '干性/混干',
              label: '干性/混干',
            },
          ],
        },
        {
          value: '面部肌肤的敏感度',
          label: '面部肌肤的敏感度',
          children: [
            {
              value: '是',
              label: '是',
            },
            {
              value: '否',
              label: '否',
            },
          ],
        },
        {
          value: '防晒用户消费级别',
          label: '防晒用户消费级别',
          children: [
            {
              value: '200以下',
              label: '200以下',
            },
            {
              value: '200以上',
              label: '200以上',
            },
          ],
        },
      ],
      formInline: {
        region: '',
        product: '',
        market: '',
      },
      formInlines: { region: '', product: '' },
      // 空数据模型
      prediciton: [
        {
          score: 4.4,
          character: '滋润感',
          percentage: '0.94',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },
        {
          score: 4.4,
          character: '黏腻度',
          percentage: '0.93',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },
        {
          score: 3.9,
          character: '吸收速度',
          percentage: '0.71',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },
        {
          score: 3.3,
          character: '增白效果',
          percentage: '0.76',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },

        {
          score: 3.5,
          character: '涂抹性',
          percentage: '0.73',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },

        {
          score: 4.6,
          character: '光滑度',
          percentage: '0.78',
          imgurl: 'https://newstore.vynior.com/ana13.png',
        },
      ],
      // 产品
      Products: [
        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC1', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066', //项目编号
          project_data: '2022/10/8', //创建日期
          name: 'Ideal 1', //产品名称
          classification: '竞品', //产品品类
          category: '防晒', //产品分类
          product_number: '300303', //产品编号
          color: '白色', //产品颜色
          product_place: '中国', //产地
          brand: 'PPP', //品牌
          quality: '4.02', //质地
          smear: '3.43', //涂抹
          absorb: '2.62', //吸收
          gloss: '3.50', //光泽
          Color: '3.23', //增加颜色数值
          remark: '', //备注
          checked: '', //多选框
          panel_attributes_data: [
            6.9, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5,
            6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5, 4.5, 6.7, 1.5,
          ], //感官数据
          overall_data: 4.55, //整体喜好分值
          overall_precision: 0.95, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.2, 0.3, 0.3, 0.1, 0.1], // 整体五种分数打分占比
          overalln_5: [
            {
              num: 3.88,
              name: '产品外观',
              percent: '94',
            },
            {
              num: 4.61,
              name: '使用后肤感',
              percent: '96',
            },
            {
              num: 4.72,
              name: '香味',
              percent: '91',
            },
            {
              num: 3.88,
              name: '延展性',
              percent: '95',
            },
            {
              num: '--',
              name: '--',
              percent: '--',
            },
            {
              num: '--',
              name: '--',
              percent: '--',
            },
          ],
          prediciton_data: [4.4, 4.4, 3.9, 3.3, 3.2, 4.2, 3.5, 4.4, 4.4, 3.7, 3.1, 4.6, 4.6, 3.7, 3.7], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
          values: 1,
          // checked: false, //本身属性
        },
      ],

      img_arr: [
        'https://newstore.vynior.com/555.png',

        'https://newstore.vynior.com/44.png',

        'https://newstore.vynior.com/333.png',

        'https://newstore.vynior.com/22.png',

        'https://newstore.vynior.com/11.png',

        'https://newstore.vynior.com/nnnew.png',
      ],

      // 空数据模型感官属性
      Productsed: [
        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC1', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066', //项目编号
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 1', //产品名称
          classification: '竞品', //产品品类
          category: '防晒', //产品分类
          product_number: '300303', //产品编号
          color: '白色', //产品颜色
          product_place: '中国', //产地
          brand: 'PPP', //品牌
          quality: '未知', //质地
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '9.2', //光泽
          remark: '', //备注
          panel_attributes_data: [
            6.8, 4.7, 6.7, 8.8, 8.5, 6.9, 7.8, 3.7, 6.8, 6.3, 7.5, 6.5, 5.8, 7.3, 6.4, 9.1, 8.7, 7.4, 6.1, 8.3, 7.6, 6.9, 8.6, 6.8, 6.4, 7.0, 6.8,
            6.2, 7.5, 6.5, 8.1, 4.8, 4.1, 5.6, 2.4, 10.6, 1.1, 5.7, 4.9, 4.1, 5.6,
          ], //感官数据
          overall_data: 3.0, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.2, 0.3, 0.3, 0.1, 0.1], // 整体五种分数打分占比
          overalln_5: [
            {
              num: 3.33,
              name: '质地',
              percent: 94,
            },
            {
              num: 4.61,
              name: '涂抹度',
              percent: 96,
            },
            {
              num: 4.72,
              name: '吸收程度',
              percent: 91,
            },
            {
              num: 1.88,
              name: '颜色',
              percent: 95,
            },
            {
              num: 4.83,
              name: '光泽度',
              percent: 99,
            },
          ],
          prediciton_data: [4.4, 4.4, 3.9, 3.3, 3.2, 4.2, 3.5, 4.4, 4.4, 3.7, 3.1, 4.6, 4.6, 3.7, 3.7], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC2', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 2',
          classification: '样品',
          category: '防晒',
          product_number: '333',
          color: '透明',
          product_place: '法国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '2.6', //光泽
          remark: '',
          panel_attributes_data: [
            4.7, 5.7, 2.4, 5.9, 7.1, 4.0, 6.8, 2.7, 6.6, 6.1, 7.4, 5.4, 4.7, 6.6, 1.7, 6.3, 9.4, 5.8, 4.7, 6.8, 6.4, 5.6, 7.5, 6.9, 6.9, 6.9, 6.9,
            6.2, 7.5, 8.6, 9.7, 5.1, 4.2, 6.3, 1.9, 5.6, 5.5, 7.1, 5.8, 4.7, 6.5,
          ],
          overall_data: 3.2, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.25, 0.2, 0.35, 0.1, 0.1], //整体五种分数打分占比
          prediciton_data: [4.2, 4.2, 4.6, 4.5, 3.7, 4.5, 3.5, 4.2, 4.0, 3.2, 3.7, 4.5, 4.6, 3.2, 3.2], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81],
          //消费者维度预测分数准确率,
        },

        {
          product_id: ' X3tucj8LYuBo638gq6pQOpC3', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 3',
          classification: '样品',
          category: '防晒',
          product_number: '2445',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '7.9', //光泽
          remark: '',
          panel_attributes_data: [
            3.5, 4.5, 2.4, 5.7, 10.8, 3.1, 7.8, 2.9, 7.0, 6.4, 7.7, 6.5, 5.8, 7.3, 1.8, 4.9, 10.7, 6.0, 5.3, 6.8, 7.2, 6.4, 8.3, 7.9, 7.8, 8.6, 7.2,
            6.6, 7.8, 7.7, 10.1, 4.3, 3.8, 5.1, 1.9, 4.8, 4.4, 6.3, 5.2, 4.4, 5.9,
          ],
          overall_data: 3.4, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.27, 0.15, 0.25, 0.23, 0.1], //整体五种分数打分占比
          prediciton_data: [3.8, 3.9, 3.2, 3.9, 4.3, 3.7, 3.1, 3.5, 3.6, 4.5, 3.9, 3.8, 3.5, 4.6, 4.3], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },
        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC4', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 4',
          classification: '市售产品',
          category: '防晒',
          product_number: '325',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            7.1, 5.4, 2.1, 7.0, 10.0, 5.4, 6.8, 2.3, 6.5, 5.9, 7.3, 5.5, 4.8, 6.5, 2.1, 7.8, 9.7, 5.3, 4.5, 6.7, 6.1, 5.4, 7.4, 7.1, 7.1, 7.0, 6.6,
            5.9, 7.4, 8.4, 9.1, 5.0, 4.1, 6.1, 1.8, 8.0, 5.7, 6.8, 5.3, 4.5, 6.2,
          ],
          overall_data: 3.62, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.27, 0.31, 0.24, 0.1, 0.08], //整体五种分数打分占比
          prediciton_data: [3.7, 3.4, 3.8, 3.1, 3.4, 3.8, 4.5, 3.6, 4.1, 3.6, 3.1, 3.3, 3.0, 3.3, 3.9], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC5', //产品_Id
          project_id: 'lRN7rsBiB2DMMONmDV7CClOQ', //项目_Id
          project_name: 'FS-066',
          project_data: '2022/10/8', //创建日期
          name: 'Prototype 5',
          classification: '市售产品',
          category: '防晒',
          product_number: '555',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            3.3, 6.3, 2.3, 2.3, 10.8, 3.0, 7.4, 6.3, 7.3, 6.8, 7.9, 6.4, 5.8, 7.0, 5.4, 6.5, 10.5, 6.3, 5.7, 7.3, 7.4, 6.6, 8.2, 6.5, 6.4, 6.6, 7.2,
            6.7, 7.8, 7.5, 9.3, 5.8, 5.0, 6.8, 4.3, 5.9, 4.1, 6.8, 5.5, 4.7, 6.3,
          ],
          overall_data: 3.75, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.37, 0.21, 0.24, 0.1, 0.08], //整体五种分数打分占比
          prediciton_data: [4.2, 3.7, 4.2, 4.0, 4.4, 3.2, 3.8, 3.5, 4.3, 3.3, 3.9, 3.0, 3.9, 4.6, 3.2], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC6', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 1',
          classification: '竞品',
          category: '防晒',
          product_number: '6343',
          color: '粉丝',
          product_place: '中国',
          brand: 'RQT',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            7.5, 5.7, 0.5, 6.5, 5.3, 5.6, 8.3, 0.6, 7.8, 7.3, 8.5, 7.6, 6.9, 8.0, 3.1, 8.3, 8.3, 7.5, 6.9, 8.4, 8.4, 7.7, 9.4, 7.0, 6.7, 7.6, 7.4,
            6.8, 8.0, 5.3, 9.7, 5.7, 5.1, 6.6, 2.1, 9.0, 8.8, 6.0, 5.1, 4.4, 5.8,
          ],
          overall_data: 3.82, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_: [0.37, 0.3, 0.14, 0.11, 0.08], //整体五种分数打分占比
          prediciton_data: [4.3, 3.6, 4.0, 4.1, 4.2, 3.1, 3.7, 3.3, 4.3, 4.0, 4.6, 3.9, 3.6, 4.3, 4.1], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC7', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 2',
          classification: '样品',
          category: '防晒',
          product_number: '99',
          color: '白色',
          product_place: '美国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            6.3, 5.4, 2.5, 8.3, 11.1, 6.4, 8.5, 2.3, 8.4, 7.7, 9.0, 7.8, 7.2, 8.5, 3.7, 9.4, 10.7, 7.3, 6.7, 8.1, 8.4, 7.7, 9.4, 7.4, 7.2, 7.8, 8.0,
            7.4, 8.7, 6.0, 8.3, 5.8, 5.2, 6.4, 4.4, 8.2, 2.2, 5.9, 5.0, 4.5, 5.6,
          ],
          overall_data: 3.52, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.23, 0.3, 0.17, 0.13, 0.17], //整体五种分数打分占比
          prediciton_data: [3.8, 4.3, 3.5, 3.9, 4.5, 3.6, 4.2, 4.2, 3.1, 4.5, 3.5, 3.4, 4.1, 3.7, 3.8], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC8', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 3',
          classification: '样品',
          categor: '防晒',
          product_number: '3434',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            3.4, 5.1, 2.7, 5.7, 10.2, 3.2, 7.5, 2.9, 7.3, 6.5, 7.8, 6.4, 5.8, 7.0, 1.9, 4.7, 10.5, 6.3, 5.4, 7.0, 6.5, 5.9, 7.5, 7.3, 6.8, 7.9, 7.5,
            6.8, 8.2, 8.0, 9.6, 5.5, 4.9, 6.0, 2.1, 4.2, 5.4, 6.5, 5.3, 4.6, 5.9,
          ],
          overall_data: 3.02, // 整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.1, 0.2, 0.25, 0.28, 0.17], //整体五种分数打分占比
          prediciton_data: [3.2, 3.3, 3.0, 3.0, 3.2, 3.2, 3.5, 3.1, 3.4, 3.0, 3.0, 3.4, 3.1, 3.3, 3.2], // 消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpC9', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 4',
          classification: '市售产品',
          category: '防晒',
          product_number: '66',
          color: '白色',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            6.9, 5.4, 2.9, 7.6, 10.3, 6.2, 8.0, 3.3, 8.1, 7.3, 8.8, 7.2, 6.6, 7.9, 4.3, 9.0, 9.4, 6.8, 6.2, 7.9, 7.3, 6.7, 8.2, 6.2, 5.9, 6.6, 8.0,
            7.3, 8.7, 6.8, 8.7, 7.0, 6.4, 7.7, 4.3, 8.9, 2.2, 6.0, 5.3, 4.7, 6.1,
          ],
          overall_data: 3.13, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], // 整体95%置信区间阈值
          overall_5: [0.1, 0.24, 0.35, 0.18, 0.13], //整体五种分数打分占比
          prediciton_data: [3.1, 3.4, 3.1, 3.2, 3.1, 3.5, 3.5, 3.3, 3.3, 3.4, 3.0, 3.3, 3.5, 3.4, 3.4], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },

        {
          product_id: 'X3tucj8LYuBo638gq6pQOpCA', //产品_Id
          project_id: 'HVRj2kY0O83Bf3q4QdAEeKPg', //项目_Id
          project_name: 'FS-008',
          project_data: '2022/10/8', //创建日期
          name: 'TT Pro 5',
          classification: '市售产品',
          category: '防晒',
          product_number: '34689',
          color: '透明',
          product_place: '中国',
          brand: 'XXX',
          quality: '未知',
          smear: '90%', //涂抹
          absorb: '99%', //吸收
          gloss: '5.2', //光泽
          remark: '',
          panel_attributes_data: [
            3.9, 4.8, 4.5, 6.5, 10.6, 3.9, 8.1, 4.8, 7.9, 7.4, 8.8, 7.1, 6.6, 8.0, 2.2, 6.1, 10.4, 7.3, 6.6, 8.4, 7.4, 6.7, 8.3, 6.8, 6.3, 7.2, 7.9,
            7.5, 8.7, 6.5, 8.7, 6.3, 5.7, 7.1, 2.0, 5.2, 2.0, 5.8, 5.3, 4.6, 5.7,
          ], //感官数据默认图表
          overall_data: 3.28, //整体喜好分值
          overall_precision: 0.87, //整体喜好准确率
          overall_90: [3.0, 3.25], //整体90%置信区间阈值
          overall_95: [3.1, 3.15], //整体95%置信区间阈值
          overall_5: [0.15, 0.26, 0.34, 0.13, 0.12], //整体五种分数打分占比
          prediciton_data: [3.7, 3.4, 3.6, 3.7, 3.7, 3.3, 3.5, 3.6, 3.8, 3.0, 3.3, 3.2, 3.2, 3.5, 3.6], //消费者维度预测分数
          prediciton_precision: [0.94, 0.93, 0.71, 0.76, 0.81, 0.77, 0.73, 0.74, 0.77, 0.8, 0.83, 0.78, 0.9, 0.86, 0.81], //消费者维度预测分数准确率
        },
      ],

      // 添加属性判断
      colors: '', //点击后默认颜色
      colorindex: 1,
      // 颜色数组
      color_list: [1, 2, 3, 4],
      // 颜色判断
      product: [
        {
          checkedss: true, //点击后的状态
          values: 1, //颜色绑定值
        },
        {
          checkedss: true, //点击后的状态
          values: 2, //颜色绑定值
        },
        {
          checkedss: true, //点击后的状态
          values: 3, //颜色绑定值
        },
        {
          checkedss: true, //点击后的状态
          values: 4, //颜色绑定值
        },
      ],
      select_arrs: ['', '', '', ''],
      selectarr: [],
      remark_index: [],
      remark_class: ['CC_Product_data', 'CC_Product_data_1', 'CC_Product_data_2', 'CC_Product_data_3', 'CC_Product_data_4'],
      remarks_class: ['CC_History_data', 'CC_History_data_1', 'CC_History_data_2', 'CC_History_data_3', 'CC_History_data_4'],
      remark_class_dot: ['dot1', 'dot2', 'dot3', 'dot4'],
      click_class: ['', '', '', ''],
      clicks_class: ['', '', '', ''],
      check: false, //默认
      colorsindex: 0,
      comprehensive_name: '综合性指标',
      features_name: '喜好度指标',
      // 推荐优化方案title替换
      tj_paln_name: [
        {
          className: 'name_three',
          title: '',
        },
        {
          className: 'name_fore',
          title: '',
        },
        {
          className: 'name_five',
          title: '',
        },
      ],
    };
  },
  components: {
    Header,
    H,
  },
  watch: {
    product_message(newval) {
      if (JSON.stringify(newval) !== {} && this.change_bar_num == 1) {
        console.log('加载柱状图 :>> ');
        this.bar_ = 1;
        this.drawChart_bar();
      }
      this.Cha = 6 - newval.compre_attributes.length;
      this.Chas = 9 - newval.charac_attributes.length;
    },

    suanfa_empty(newval) {
      console.log('newval :>> ', newval);
      if (newval == 0) {
        this.suanfa_emptys = 5;
      }
    },
    choose_name() {
      if (this.choose_name.length == 0) {
        // this.map_arrs = JSON.parse(JSON.stringify(this.map_arr));
        this.map_arrs = JSON.parse(JSON.stringify(this.maps));
        this.map_arrss = JSON.parse(JSON.stringify(this.maps));
      }
    },
    // 项目
    'formInline.product': {
      handler(newr, oldr) {
        if (oldr == '') {
          if (newr != oldr) {
            console.log(newr.split('/'));
            this.choose_pj_name = newr.split('/')[0];
            this.choose_xm_name = newr.split('/')[1];
            this.product_name = [];
            this.req_UserProduct();
            this.clear_choose();
            // this.formInlines.product = "";
            this.suanfa_empty = 1;

            console.log(this.choose_product);
          }
        } else {
          if (newr != oldr) {
            console.log(newr.split('/'));
            this.choose_pj_name = newr.split('/')[0];
            this.choose_xm_name = newr.split('/')[1];
            this.req_UserProduct();
            this.clear_choose();
            this.formInlines.product = '';
            // this.suanfa_empty = 1;

            console.log(this.choose_product);
          }
        }
      },
      immediate: true,
      // deep: true,
    },
    // 产品
    'formInlines.product': {
      handler(newr, oldr) {
        console.log(newr, 'newr', oldr, 'oldr');
        if (newr.indexOf('优化方案') == -1) {
          console.log('选择的没有优化产品');
        } else {
          this.formInlines.product = oldr;
          console.log('选择的有优化产品');
        }
        if (newr != oldr) {
          // this.history_name="全部"
          console.log(newr);
          this.choose_product = newr;
          this.clear_choose();
          // 请求优化方案
          // this.suanfa_empty = 1;
          // this.suanfa_emptys = 1;
          this.caihong_ = 0;
          this.overall90_1 = 0.0;
          this.overall90_2 = 0.0;

          this.overall95_1 = 0.0;
          this.overall95_2 = 0.0;
          this.history_cancel = 1;
        }

        if (newr == '') {
          this.history_cancel = 0;
        }
      },
    },
    //细分市场
    market: {
      handler(newl) {
        console.log('newl :>> ', newl);

        // ! 判断每一类的逻辑
        console.log(newl[0], newl[1], newl[0] == '年龄', newl[1] == '18-25', newl[1] === '18-25');
        // // 定义一个新的结果数组
        if (newl.length > 1) {
          if (newl[0] == '无' || newl[0] == 'NA') {
            this.clear_chooses();
          }
        }
        console.log('市场长度为', newl.length);
        console.log('市场长度为', newl[0]);
        console.log('市场长度为', newl);

        this.segment_1 = [0, 0, 0, 0, 0];
        this.segment_2 = [0, 0, 0];
        this.segment_3 = [0, 0];
        this.segment_4 = [0, 0];
        this.segment = ['00000', '000', '00', '00'];
        this.sement_sum = [];
        if (newl.length > 1) {
          if (newl[0] == '无' || newl[0] == 'NA') {
            this.clear_chooses();
          }
        }
        if (newl.length == 1) {
          if (newl[0] == '无' || newl[0] == 'NA') {
            this.sexnum = 0;
          }
        }

        if (newl[0] == '年龄段' || newl[0] == 'Age') {
          console.log('设置年龄');
          if (newl[1] == '18-25') {
            console.log('设置年龄1');
            this.sement_sum.push(['18-25', '18-25']);

            this.$set(this.segment_1, 0, 1);
            console.log(this.segment_1);
          } else if (newl[1] == '26-35') {
            console.log('设置年龄2');
            this.sement_sum.push(['26-35', '26-35']);
            this.$set(this.segment_1, 1, 1);
            console.log(this.segment_1);
          } else if (newl[1] == '36-45') {
            console.log('设置年龄3');
            this.$set(this.segment_1, 2, 1);
            this.sement_sum.push(['36-45', '36-45']);
            console.log(this.segment_1);
          } else if (newl[1] == '18-35') {
            this.$set(this.segment_1, 3, 1);
            this.sement_sum.push(['18-35', '18-35']);
            console.log(this.segment_1);
          } else if (newl[1] == '26-45') {
            this.$set(this.segment_1, 4, 1);
            this.sement_sum.push('26-45', '26-45');
            console.log(this.segment_1);
          }
          // ! 重写逻辑
        } else if (newl[0] == '肤质' || newl[0] == 'Skin Type') {
          console.log('设置肤质');
          if (newl[1] == '油性/混油' || newl[1] == 'Oil/mixed Oil') {
            this.sement_sum.push(['油性/混油', 'Oil/mixed Oil']);
            this.$set(this.segment_2, 0, 1);
            console.log(this.segment_2);
          } else if (newl[1] == '中性' || newl[1] == 'Neutral') {
            this.$set(this.segment_2, 1, 1);
            this.sement_sum.push(['中性', 'Neutral']);
            console.log(this.segment_2);
          } else if (newl[1] == '干性/混干' || newl[1] == 'Dry/Mixed Dry') {
            this.$set(this.segment_2, 2, 1);
            this.sement_sum.push(['干性/混干', 'Dry/Mixed Dry']);
            console.log(this.segment_2);
          }
        } else if (newl[0] == '面部肌肤的敏感度' || newl[0] == 'Sensitive Skin') {
          console.log('设置面部肌肤的敏感度');
          if (newl[1] == '是' || newl[1] == 'Yes') {
            this.sement_sum.push(['是', 'Yes']);
            this.$set(this.segment_3, 0, 1);
            console.log(this.segment_3);
          } else if (newl[1] == '否' || newl[1] == 'No') {
            this.sement_sum.push(['否', 'No']);
            this.$set(this.segment_3, 1, 1);
            console.log(this.segment_3);
          }
        } else if (newl[0] == '防晒用户消费级别' || newl[0] == 'Consumer Level of Sunscreen Users') {
          console.log('设置防晒用户消费级别');
          if (newl[1] == '200以下' || newl[1] == 'Under 200 RMB') {
            this.$set(this.segment_4, 0, 1);
            this.sement_sum.push(['200以下', 'Under 200 RMB']);
            console.log(this.segment_4);
          } else if (newl[1] == '200以上' || newl[1] == 'Over 200 RMB') {
            this.sement_sum.push(['200以上', 'Over 200 RMB']);
            this.$set(this.segment_4, 1, 1);
            console.log(this.segment_4);
          }
        } else {
          this.sement_sum.push(['无', 'NA']);
        }

        this.$set(this.segment, 0, this.segment_1.join(''));
        this.$set(this.segment, 1, this.segment_2.join(''));
        this.$set(this.segment, 2, this.segment_3.join(''));
        this.$set(this.segment, 3, this.segment_4.join(''));
      },
    },

    //感官属性数据的 四个彩色数据
    selectarr: {
      handler(newr, oldr) {
        console.log(oldr);
        console.log(newr);
        this.choose_products = [];
        let segmentNull = '';
        newr.forEach((item, index) => {
          this.choose_products.push(item.id);
          console.log('分析输出内容', item.segment);
          // this.sement_sum2 = ["", "", "", "", ""];
          // 替换中-》英文
          if (localStorage.lang == 'en') {
            switch (item.segment) {
              case '无':
                segmentNull = 'NA';
                break;
              case '油皮/混油皮':
                segmentNull = 'Oil/mixed Oil';
                break;
              case '中性':
                segmentNull = 'Neutral';
                break;
              case '干皮/混干皮':
                segmentNull = 'Dry/Mixed Dry';
                break;
              case '是':
                segmentNull = 'Yes';
                break;
              case '否':
                segmentNull = 'No';
                break;
              case '200以下':
                segmentNull = 'Under 200 RMB';
                break;
              case '200以上':
                segmentNull = 'Over 200 RMB';
                break;
              default:
                segmentNull = item.segment;
            }
          }

          console.log(segmentNull, 'segmentNull,segmentNull,segmentNull');
          this.$set(this.sement_sum2, index, segmentNull);
        });
      },
    },
    //FIXME 监听重要度
    important: {
      handler(newval) {
        console.log(newval);
        if (newval == 0) {
          this.important_select = 0;
        } else if (newval == 1) {
          this.important_select = 1;
        }
      },
    },
    // 监听感官属性名称
    'options_search.label': {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          console.log(newVal);
        }
      },
    },
    // 监听历史产品选择条件
    history_name: {
      handler(newv, oldv) {
        console.log(newv, oldv);
        if (newv != oldv) {
          // this.clear_choose();
        }
        // this.oldHis = JSON.parse(JSON.stringify(this.history));
        console.log(this.oldHis);
        if (newv == '市售产品' || newv == 'In-market Product') {
          //  ! 市售产品
          let arr = [];
          for (let index = 0; index < this.history1.length; index++) {
            if (this.history1[index].classification == '市售产品') {
              arr.push(this.history1[index]);
            }
          }
          this.history = arr;
        } else if (newv == '开发原型' || newv == 'Prototype') {
          let arr = [];
          for (let index = 0; index < this.history1.length; index++) {
            if (this.history1[index].classification == '开发原型') {
              arr.push(this.history1[index]);
            }
          }
          this.history = arr;
        } else if (newv == '竞争品牌' || newv == 'Benchmark') {
          let arr = [];
          for (let index = 0; index < this.history1.length; index++) {
            if (this.history1[index].classification == '竞争品牌') {
              arr.push(this.history1[index]);
            }
          }
          this.history = arr;
        } else if (newv == '全部' || newv == 'All') {
          this.history = this.history1;
        }
      },
    },
    // ! 监听选择数据，进行切换
    '$store.state.lang': {
      handler() {
        // this.onecode.forEach((item,index)=>{
        //       console.log(item,index,111);
        //       console.log(this.onecode[index][0] =="年龄",this.onecode[index][0],111);
        //       if(this.onecode[index][0] =="年龄"){
        //                this.$set(this.onecode[index],0,"Age")
        //       }
        // })
        //  console.log( this.onecode[0].slice(0,2),111);
        //  console.log(this.onecode[0].slice(0,2) == ['性别', '男'],111);
        //  console.log(this.onecode[0][0] == '性别',111,this.onecode[0][1] == '男');
        //             console.log( this.onecode.includes([["Gender","Male"]]),111);
        if (this.$store.state.lang == 'cn') {
          this.options = this.options_cn;
          for (let index = 0; index < this.echarts_name.length; index++) {
            if (this.echarts_name[index] == 'optimal1') {
              this.$set(this.echarts_name, index, '优化方案1');
            } else if (this.echarts_name[index] == 'optimal2') {
              this.$set(this.echarts_name, index, '优化方案2');
            } else if (this.echarts_name[index] == 'optimal3') {
              this.$set(this.echarts_name, index, '优化方案3');
            }
          }
          this.$set(this.recommend[0], 'product_name', '优化方案1');
          this.$set(this.recommend[1], 'product_name', '优化方案2');
          this.$set(this.recommend[2], 'product_name', '优化方案3');
          this.drawChart_bar();
          this.drawChart();
          this.likeoptions = [
            {
              //下拉框的数据
              value: 'ALL SPF',
              label: 'ALL SPF',
            },
            {
              value: 'SPF≥50',
              label: 'SPF≥50',
              disabled: true,
            },
            {
              value: 'SPF<50',
              label: 'SPF<50',
            },
            {
              value: '物理防晒',
              label: '物理防晒',
            },
            {
              value: '化学防晒',
              label: '化学防晒',
            },
            {
              value: '物化结合',
              label: '物化结合',
            },
          ];
          switch (this.likevalue) {
            case 'Chemical':
              this.likevalue = '物理防晒';
              break;
            case 'Physical':
              this.likevalue = '化学防晒';
              break;
            case 'Hybird':
              this.likevalue = '物化结合';
              break;
            case 'Not displayed':
              this.likevalue = '不显示';
              break;
          }

          //!历史产品选择
          switch (this.history_name) {
            case 'All':
              this.history_name = '全部';
              break;
            case 'In-market Product':
              this.history_name = '市售产品';
              break;
            case 'Prototype':
              this.history_name = '开发原型';
              break;
            case 'Benchmark':
              this.history_name = '竞争品牌';
              break;
          }
        } else if (this.$store.state.lang == 'en') {
          this.options = this.options_en;
          // !遍历内容 更改内容
          for (let index = 0; index < this.echarts_name.length; index++) {
            if (this.echarts_name[index] == '优化方案1') {
              this.$set(this.echarts_name, index, 'optimal1');
            } else if (this.echarts_name[index] == '优化方案2') {
              this.$set(this.echarts_name, index, 'optimal2');
            } else if (this.echarts_name[index] == '优化方案3') {
              this.$set(this.echarts_name, index, 'optimal3');
            }
          }
          this.$set(this.recommend[0], 'product_name', 'optimal1');
          this.$set(this.recommend[1], 'product_name', 'optimal2');
          this.$set(this.recommend[2], 'product_name', 'optimal3');
          this.drawChart_bar();
          this.drawChart();

          this.likeoptions = [
            {
              //下拉框的数据
              value: 'ALL SPF',
              label: 'ALL SPF',
            },
            {
              value: 'SPF≥50',
              label: 'SPF≥50',
              disabled: true,
            },
            {
              value: 'SPF<50',
              label: 'SPF<50',
            },
            {
              value: 'Chemical',
              label: 'Chemical',
            },
            {
              value: 'Physical',
              label: 'Physical',
            },
            {
              value: 'Hybird',
              label: 'Hybird',
            },
          ];
          // !切换判断赋值
          switch (this.likevalue) {
            case '物理防晒':
              this.likevalue = 'Chemical';
              break;
            case '化学防晒':
              this.likevalue = 'Physical';
              break;
            case '物化结合':
              this.likevalue = 'Hybird';
              break;
            case '不显示':
              this.likevalue = 'Not displayed';
              break;
          }
          switch (this.history_name) {
            case '全部':
              this.history_name = 'All';
              break;
            case '市售产品':
              this.history_name = 'In-market Product';
              break;
            case '开发原型':
              this.history_name = 'Prototype';
              break;
            case '竞争品牌':
              this.history_name = 'Benchmark';
              break;
          }
        }
        if (this.recommend.length !== 0) {
          this.tj_paln_name[0].title = this.$store.state.lang == 'cn' ? this.recommend[0].optimization[0] : this.recommend[0].optimization_en[0];
          this.tj_paln_name[1].title = this.$store.state.lang == 'cn' ? this.recommend[0].optimization[1] : this.recommend[0].optimization_en[1];
          this.tj_paln_name[2].title = this.$store.state.lang == 'cn' ? this.recommend[0].optimization[2] : this.recommend[0].optimization_en[2];
        }
      },
    },
    //感官属性数据的 四个彩色数据
    showCut: {
      handler(newv) {
        console.log(newv);
        // <50
        if (newv == 2) {
          this.$set(this.one, 0, '61%');
          this.$set(this.one, 1, '65%');
          this.$set(this.one, 2, '71.5%');
          this.$set(this.one, 3, '72.5%');
          this.$set(this.s_f, 0, 3.43);
          this.$set(this.s_f, 1, 3.79);
          this.$set(this.s_f, 2, 3.91);
          this.$set(this.s_f, 3, 3.94);
        }
        // all
        else if (newv == 0) {
          this.$set(this.one, 0, '61.5%');
          this.$set(this.one, 1, '64.25%');
          this.$set(this.one, 2, '71.75%');
          this.$set(this.one, 3, '75%');
          this.$set(this.s_f, 0, 3.57);
          this.$set(this.s_f, 1, 3.78);
          this.$set(this.s_f, 2, 3.93);
          this.$set(this.s_f, 3, 4.11);
        }
        // >= 50
        else if (newv == 3) {
          console.log('123');
          // one: [411, 443, 454, 457],//四分线位置
          this.$set(this.one, 0, '66.25%');
          this.$set(this.one, 1, '70.5%');
          this.$set(this.one, 2, '74%');
          this.$set(this.one, 3, '78%');
          this.$set(this.s_f, 0, 3.64);
          this.$set(this.s_f, 1, 3.78);
          this.$set(this.s_f, 2, 3.97);
          this.$set(this.s_f, 3, 4.12);
        }
        // 物理防晒
        else if (newv == 4) {
          console.log('123');
          // one: [411, 443, 454, 457],//四分线位置
          this.$set(this.one, 0, '62%');
          this.$set(this.one, 1, '64.25%');
          this.$set(this.one, 2, '66.6%');
          this.$set(this.one, 3, '68.4%');
          this.$set(this.s_f, 0, 3.5);
          this.$set(this.s_f, 1, 3.59);
          this.$set(this.s_f, 2, 3.77);
          this.$set(this.s_f, 3, 3.9);
        }
        // 化学防晒
        else if (newv == 5) {
          console.log('123');
          // one: [411, 443, 454, 457],//四分线位置
          this.$set(this.one, 0, '64.3%');
          this.$set(this.one, 1, '68.5%');
          this.$set(this.one, 2, '72.7%');
          this.$set(this.one, 3, '76%');
          this.$set(this.s_f, 0, 3.66);
          this.$set(this.s_f, 1, 3.82);
          this.$set(this.s_f, 2, 3.94);
          this.$set(this.s_f, 3, 4.13);
        }
        // 雾化结合
        else if (newv == 6) {
          console.log('123');
          // one: [411, 443, 454, 457],//四分线位置
          this.$set(this.one, 0, '67.5%');
          this.$set(this.one, 1, '73.1%');
          this.$set(this.one, 2, '74.9%');
          this.$set(this.one, 3, '77%');
          this.$set(this.s_f, 0, 3.73);
          this.$set(this.s_f, 1, 3.91);
          this.$set(this.s_f, 2, 4.03);
          this.$set(this.s_f, 3, 4.14);
        }
      },
    },
    //监听勾选数组
    'click_class.length': {
      handler(newr) {
        // console.log(oldr);
        console.log('数组的长度为:', newr);
      },
    },
    //监听防晒范围的;
    // showCut: {
    //   handler(newv, ) {
    //     console.log(newv);
    //     // >50
    //     if (newv == 2) {
    //       this.$set(this.one, 0, 352);
    //       this.$set(this.one, 1, 369);
    //       this.$set(this.one, 2, 389);
    //       this.$set(this.one, 3, 393);
    //       this.$set(this.s_f, 0, 3.48);
    //       this.$set(this.s_f, 1, 3.68);
    //       this.$set(this.s_f, 2, 3.9);
    //       this.$set(this.s_f, 3, 3.94);
    //     }
    //     // all
    //     else if (newv == 0) {
    //       this.$set(this.one, 0, 368);
    //       this.$set(this.one, 1, 385);
    //       this.$set(this.one, 2, 393);
    //       this.$set(this.one, 3, 404);
    //       this.$set(this.s_f, 0, 3.66);
    //       this.$set(this.s_f, 1, 3.85);
    //       this.$set(this.s_f, 2, 3.95);
    //       this.$set(this.s_f, 3, 4.1);
    //     }
    //     // 50
    //     else {
    //       // one: [411, 443, 454, 457],//四分线位置
    //       this.$set(this.one, 0, 374);
    //       this.$set(this.one, 1, 389);
    //       this.$set(this.one, 2, 396);
    //       this.$set(this.one, 3, 408);
    //       this.$set(this.s_f, 0, 3.73);
    //       this.$set(this.s_f, 1, 3.9);
    //       this.$set(this.s_f, 2, 4);
    //       this.$set(this.s_f, 3, 4.12);
    //     }
    //   },
    // },
    //监听history,
    history: {
      handler: function () {
        this.history.forEach((item, index) => {
          if (this.num == 1) {
            this.history[index].checked = false;
            this.history1 = this.history;
            this.num = 2;
          }
        });
      },
    },
    // history_click: {
    //   handler(Newindex, Old) {
    //     console.log('数组值发生变化');
    //     let istrue = 0;
    //     this.history_click.forEach((item, index) => {
    //       if (item == true) {
    //         ++istrue;
    //         if (istrue == 4) {
    //           Newindex = Old;
    //           this.history[index].checked = Old;
    //         }
    //       }
    //       console.log(istrue, 'istrue');
    //     });
    //   },
    //   deep: true,
    // },
    //     "bar_name":{
    //       handler(){
    //   // ! 判断有没有 有就进行去重什么的
    //   if(this.bar_name){
    // const arr = this.bar_name
    // const newArr = arr.map(item => {
    //    if (item.includes("ideal")) {
    //       return "优化方案1";
    //     } else {
    //       return item;
    //     }
    // });

    // console.log(newArr);
    //   }
    //       }
    //     }
  },
  computed: {
    colorsindex: function () {
      console.log(this.colorsindex, 'colorsindex');
      return this.remark_class[this.colorsindex];
    },
    computedclass() {
      return function (data) {
        console.log(data, 'computedclass');
        var str = 'CC_Product_data';
        switch (data) {
          case 1:
            str = 'CC_Product_data_1';
            break;
          case 2:
            str = 'CC_Product_data_2';
            break;
          case 3:
            str = 'CC_Product_data_3';
            break;
          case 4:
            str = 'CC_Product_data_4';
            break;
        }
        return str;
      };
    },
    computedclass2() {
      return function (data) {
        console.log(data, 'computedclass2');
        // console.log(data, 8888);
        var str = 'CC_History_data';
        switch (data) {
          case 1:
            str = 'CC_History_data_1';
            break;
          case 2:
            str = 'CC_History_data_2';
            break;
          case 3:
            str = 'CC_History_data_3';
            break;
          case 4:
            str = 'CC_History_data_4';
            break;
          case 5:
            str = 'CC_History_data';
            break;
        }
        return str;
      };
    },
    computeddot() {
      return function (data) {
        var index = data.colorsindex;
        var str = '';
        switch (index) {
          case 1:
            str = 'dot1';
            break;
          case 2:
            str = 'dot2';
            break;
          case 3:
            str = 'dot3';
            break;
          case 4:
            str = 'dot4';
            break;
        }
        return str;
      };
    },
    computeddotyuan() {
      return function (data) {
        var index = data.colorsindex;
        var str = '';
        switch (index) {
          case 1:
            str = 'dot1_num';
            break;
          case 2:
            str = 'dot2_num';
            break;
          case 3:
            str = 'dot3_num';
            break;
          case 4:
            str = 'dot4_num';
            break;
        }
        return str;
      };
    },
  },
  methods: {
    // 帮助中心子组件传给本组件
    unloding1(e) {
      console.log(e);
      console.log('触发父元素');
      this.$on(e, (this.helpDialogRole = false));
    },
    //note添加到项目 V2.0新增功能
    async addProgram() {
      //获取推荐产品的id;
      let idealId = [];
      for (let index = 0; index < 3; index++) {
        console.log('this.history_click[index] :>> ', this.history_click[index]);
        if (this.history_click[index] == true) {
          idealId.push({ id: this.recommend[index].id, key: index });
        }
      }
      // console.log('idealId :>> ', idealId);
      if (idealId.length == 0) {
        message({
          type: 'warning',
          message: this.$t(`message.Message.selectCPs`),
          showClose: true,
        });
      } else {
        let res = await HttpClient.post('program/addIdealToProgram', {
          idealList: idealId,
          id: this.project_id,
          name: this.choose_product,
        });
        // console.log('res :>> ', res);
        if (res.message == 'succcess') {
          message({
            type: 'success',
            message: this.$t(`message.itemAnalyse.Successfully`),
            showClose: true,
          });
        } else {
          message({
            type: 'warning',
            message: this.$t(`message.itemAnalyse.noadd`),
            showClose: true,
          });
        }
      }
    },

    changeNname(e) {
      console.log(e);
    },

    changhelpDialogRole() {
      console.log('触发');
      this.helpDialogRole = true;
    },
    // NOTE loading关闭按钮
    loadingClose() {
      // 修改次数数据
      this.Anay[this.Anay.length - 1] = 0;
      // eriveIdeal;
      clearInterval(this.timer);
      this.loading = false;
      this.loadingProgress = 0;
      this.timer = null;
      this.analyzeStatus = true;
    },
    // NOTE导出理想产品
    async deriveIdeal() {
      console.log('this.choose_products :>> ', this.choose_products);
      let productList = [];
      // 剔除与recomend相同的id
      for (let item of this.choose_products) {
        let HaveSame = this.recommend.some((items) => items.id === item);
        // console.log(HaveSame, '检查返回状态');
        if (!HaveSame) {
          productList.push(item);
        }
      }
      for (let item of this.recommend) {
        productList.push(item.id);
      }
      productList.unshift(this.product_id);
      // console.log('productList :>> ', productList);
      let response = await HttpClient.post(
        'result/derive_new',
        {
          productList,
          market: this.segment,
          language: this.$store.state.lang,
        },
        { responseType: 'arraybuffer' }
      );
      console.log('response1111111111111111111', response);
      let name = getFilename(response);
      console.log('name', name);
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      });
      let downloadElement = document.createElement('a');
      let oneProjecthref = window.URL.createObjectURL(blob);
      downloadElement.href = oneProjecthref;
      if (this.$store.state.lang == 'cn' && name == 'FAST-单品分析理想产品.xlsx') {
        downloadElement.download = `${name}`;
      } else if (this.$store.state.lang == 'en' && name == 'FAST-单品分析理想产品.xlsx') {
        downloadElement.download = `FAST-Single Product Analysis.xlsx`;
      } else {
        downloadElement.download = `${name}`;
      }
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement);
      window.URL.revokeObjectURL(oneProjecthref);
    },
    // 调整先后顺序显示
    change_zindex1() {
      this.zindex = this.zindex + 1;
      const dod = document.querySelectorAll('.dot1');
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
      // console.log(this.zindex);
    },
    // 调整先后顺序显示
    change_zindex2() {
      this.zindex = this.zindex + 1;
      const dod = document.querySelectorAll('.dot2');
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
      // console.log(this.zindex);
    },
    // 调整先后顺序显示
    change_zindex3() {
      this.zindex = this.zindex + 1;
      const dod = document.querySelectorAll('.dot3');
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
      // console.log(this.zindex);
    },
    // 调整先后顺序显示
    change_zindex4() {
      this.zindex = this.zindex + 1;
      const dod = document.querySelectorAll('.dot4');
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
      // console.log(this.zindex);
    },

    change_zindextext(v) {
      this.zindex = this.zindex + 1;
      const dod = document.querySelectorAll(`.dot${v + 1}`);
      const colorDod = document.querySelectorAll(`.color_dot${v + 1}`);
      colorDod.forEach((item) => {
        // console.log('item :>> ', item);
        item.style.zIndex = this.zindex;
      });
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
      console.log();
    },
    //调整先后顺序显示
    change_zindex(e) {
      console.log(e, 'e.target');
      var all = e.target.getAttribute('class');
      console.log(all.toString());
      if (!all) {
        all = '';
      }
      var dod = document.querySelectorAll('.' + all.toString());
      console.log(dod);
      this.zindex = this.zindex + 1;
      const colorDod = document.querySelectorAll(`.color_${all.toString()}`);
      colorDod.forEach((item) => {
        // console.log('item :>> ', item);
        item.style.zIndex = this.zindex;
      });
      dod.forEach((item) => {
        item.style.zIndex = this.zindex;
      });
    },
    //点击柱状图
    change_bar() {
      this.change_bar_num = 1; //样式
      this.suanfa_emptys = 5; //空的彩虹图==0
      this.suanfa_empty = 5; //有数据的彩虹图==0
      this.caihong_ = 0; //精准度
      console.log('this.product-message :>> ', this.product_message);
      console.log('object :>> ', JSON.stringify(this.product_message) !== '{}');
      if (JSON.stringify(this.product_message) !== '{}') {
        // 有数据
        // this.drawChart_bar();
        this.bar_ = 1; //有数据柱状图==1//无数据==0
        this.change_num = 0;
        this.caihong_ = 0;
      } else {
        //无数据
        this.bar_ = 2; //柱状图
        this.drawChart_bar_empty();
        this.change_num = 0;
      }
    },
    //点击彩虹图
    change_() {
      this.change_num = 1;
      this.change_bar_num = 0;
      this.bar_ = 0;
      if (JSON.stringify(this.product_message) !== '{}') {
        //有数据

        this.suanfa_empty = 0;
      } else {
        //无数据
        this.suanfa_emptys = 1;
      }
    },
    // 柱状图
    drawChart_bar() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById('main_bar'));
      // 指定图表的配置项和数据

      let option = {
        title: {
          text: 'World Population',
          show: false,
        },

        tooltip: {
          // trigger: "axis",
          // axisPointer: {
          //   // type: "shadow",
          // },
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
          // position: ["40%", "-25%"], //配置提示框的位置
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 0,
          textStyle: {
            // 文字提示样式
            color: '#fff',
            fontSize: '12',
          },
          formatter: (params) => {
            // console.log(params);
            if (params.name.length > 30) {
              console.log('params.name :>> ', params.name);
              params.name = params.name.slice(0, 30) + '...';
              // console.log('名字过长');
            }
            let num =
              params.dataIndex == 0
                ? this.sement_sum_show
                : params.dataIndex == 1
                ? this.sement_sum2[0]
                : params.dataIndex == 2
                ? this.sement_sum2[1]
                : params.dataIndex == 3
                ? this.sement_sum2[2]
                : this.sement_sum2[3];
            console.log(num, 'num');
            if (this.$store.state.lang === 'en' && num == '无') {
              num = 'NA';
            }
            var res =
              '<p>' +
              this.$t(`message.itemAnalyse.productName`) +
              `${params.name}` +
              '&nbsp&nbsp&nbsp&nbsp' +
              this.$t(`message.itemAnalyse.Values`) +
              `${params.value}` +
              '</p>' +
              this.$t(`message.itemAnalyse.TargetConsumers`) +
              `${num}`;
            console.log(res, '柱状图');
            // console.log(this.sement_sum ,'this.sement_sum2');
            return res;
          },
        },
        legend: {},
        grid: {
          top: '0%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          max: 5,
          min: 1,
        },

        yAxis: {
          type: 'category',

          data: this.bar_name,

          axisLabel: {
            formatter: function (value) {
              if (value.length > 7) {
                return value.substring(0, 7) + '...';
              } else if (value.length < 11) {
                let l = 10;
                var s = l - value.length;
                var values = value;
                for (var i = 0; i < s; i++) {
                  values = ' ' + values;
                }
                return values;
              } else {
                return value;
              }
            },
          },
        },
        series: [
          {
            // name: '2011',
            type: 'bar',
            // data: [2, 4, 3.8, 3, 4],
            data: this.echarts_data,
            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  // var colorList = ['#','#','#','#','#'];
                  var colorList = ['#1D5CA6', '#E32C84', '#00B7AF', '#6255F3', '#EE9201'];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // 空柱状图
    // 柱状图
    drawChart_bar_empty() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById('main_bar_empty'));
      // 指定图表的配置项和数据

      let option = {
        title: {
          text: 'World Population',
          show: false,
        },

        legend: {},
        grid: {
          top: '0%',
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          max: 5,
          min: 1,
        },
        // tooltip: {
        //   trigger: "item",
        //   axisPointer: {
        //     // 坐标轴指示器，坐标轴触发有效
        //     type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
        //   },
        //   // position: ["40%", "-25%"], //配置提示框的位置
        //   backgroundColor: "rgba(32, 33, 36,.7)",
        //   borderColor: "rgba(32, 33, 36,0.20)",
        //   borderWidth: 0,
        //   textStyle: {
        //     // 文字提示样式
        //     color: "#fff",
        //     fontSize: "12",
        //   },
        // },
        yAxis: {
          type: 'category',

          // data: this.bar_name,
          data: ['--', '--', '--', '--', '--'],
          axisLabel: {
            formatter: function (value) {
              if (value.length > 7) {
                return value.substring(0, 7) + '...';
              } else if (value.length < 11) {
                let l = 10;
                var s = l - value.length;
                var values = value;
                for (var i = 0; i < s; i++) {
                  values = ' ' + values;
                }
                return values;
              } else {
                return value;
              }
            },
          },
        },
        series: [
          {
            // name: '2011',
            type: 'bar',
            data: [0, 0, 0, 0, 0],
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(235, 245, 254, 0.50)',
            },
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // 彩虹图
    drawChart() {
      // setTimeoutdrawChart

      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById('main'));
      // 指定图表的配置项和数据

      let option = {
        backgroundColor: '#fff',
        title: {
          text: '',
          textStyle: {
            color: '#000',
            fontSize: 32,
            fontWeight: 'bold',
          },

          left: 'center',
          top: 100,
          bottom: '69%',
          itemGap: 60,
        },
        // 配置全局的tootip 提示框
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
          },
          position: ['38%', '-25%'], //配置提示框的位置
          backgroundColor: 'rgba(32, 33, 36,.7)',
          borderColor: 'rgba(32, 33, 36,0.20)',
          borderWidth: 0,
          textStyle: {
            // 文字提示样式
            color: '#fff',
            fontSize: '12',
          },
        },
        // color: ["#01dadc", "#23cea7", "#5096e0"],

        series: [
          // 第二层
          {
            name: this.echarts_name[1],
            type: 'pie',
            //起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            tooltip: {
              // show: this.echarts_name[2],
              formatter: (params) => {
                // console.log(params);
                // console.log(params, 'paramsparamsparams');
                // let name = params.name;

                if (params.seriesName.length > 30) {
                  params.seriesName = params.seriesName.slice(0, 30) + '...';
                  // console.log('名字过长');
                }
                // console.log(params, 'paramsparamsparams');
                var res =
                  '<p>' +
                  this.$t(`message.itemAnalyse.productName`) +
                  `${params.seriesName}` +
                  '&nbsp&nbsp&nbsp&nbsp' +
                  this.$t(`message.itemAnalyse.Values`) +
                  `${params.value}` +
                  '</p>' +
                  this.$t(`message.itemAnalyse.TargetConsumers`) +
                  this.sement_sum2[0];
                return res;
              },
            },
            radius: ['110%', '124%'],
            center: ['48%', '105%'], //
            // tooltip: {
            //   show: this.echarts_name[1],
            // },
            label: {
              normal: {
                show: false,
                position: 'center',
                color: '#fff',
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                // this.echarts_index[1]
                value: this.echarts_data[1],
                itemStyle: {
                  normal: {
                    color: 'rgba(227, 44, 132, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_data[1],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
                tooltip: { show: false },
              },
            ],
          },
          // 第一层
          {
            name: this.formInlines.product,
            type: 'pie',
            //起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            tooltip: {
              formatter: (params) => {
                // console.log(params);
                if (params.seriesName.length > 30) {
                  params.seriesName = params.seriesName.slice(0, 30) + '...';
                  // console.log('名字过长');
                }
                var res =
                  '<p>' +
                  this.$t(`message.itemAnalyse.productName`) +
                  `${params.seriesName}` +
                  '&nbsp&nbsp&nbsp&nbsp' +
                  this.$t(`message.itemAnalyse.Values`) +
                  `${params.value}` +
                  '</p>' +
                  this.$t(`message.itemAnalyse.TargetConsumers`) +
                  `${this.$store.state.lang === 'cn' ? this.sement_sum[0][0] : this.sement_sum[0][1]}`;
                return res;
              },
            },
            radius: ['90%', '104%'],
            center: ['48%', '105%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                color: '#fff',
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                // 满值的颜色
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                // 效果值的颜色
                value: this.echarts_data[0],
                itemStyle: {
                  normal: {
                    color: 'rgba(29, 92, 166, 1)',
                  },
                },
              },
              {
                // 多于值的颜色
                value: 5 - this.echarts_data[0],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
                tooltip: { show: false },
              },
            ],
          },
          // 第三层
          {
            name: this.echarts_name[2],
            type: 'pie',
            startAngle: 0,
            radius: ['130%', '144%'],
            center: ['48%', '105%'],
            legendHoverLink: false,
            hoverAnimation: false,
            avoidLabelOverlap: false,
            tooltip: {
              // show: this.echarts_name[2],
              formatter: (params) => {
                if (params.seriesName.length > 30) {
                  params.seriesName = params.seriesName.slice(0, 30) + '...';
                  // console.log('名字过长');
                }
                // console.log(params);
                var res =
                  '<p>' +
                  this.$t(`message.itemAnalyse.productName`) +
                  `${params.seriesName}` +
                  '&nbsp&nbsp&nbsp&nbsp' +
                  this.$t(`message.itemAnalyse.Values`) +
                  `${params.value}` +
                  '</p>' +
                  this.$t(`message.itemAnalyse.TargetConsumers`) +
                  this.sement_sum2[1];
                return res;
              },
            },
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_data[2],
                itemStyle: {
                  normal: {
                    color: 'rgba(0, 183, 175,1)',
                  },
                },
              },
              {
                // 2
                value: 5 - this.echarts_data[2],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
                tooltip: { show: false },
              },
            ],
          },
          // 第四层
          {
            name: this.echarts_name[3],
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ['150%', '164%'],
            center: ['48%', '105%'],
            tooltip: {
              // show: this.echarts_name[3],
              formatter: (params) => {
                // console.log(params);
                if (params.seriesName.length > 30) {
                  params.seriesName = params.seriesName.slice(0, 30) + '...';
                  // console.log('名字过长');
                }
                var res =
                  '<p>' +
                  this.$t(`message.itemAnalyse.productName`) +
                  `${params.seriesName}` +
                  '&nbsp&nbsp&nbsp&nbsp' +
                  this.$t(`message.itemAnalyse.Values`) +
                  `${params.value}` +
                  '</p>' +
                  this.$t(`message.itemAnalyse.TargetConsumers`) +
                  this.sement_sum2[2];
                return res;
              },
            },
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_data[3],
                itemStyle: {
                  normal: {
                    color: 'rgba(98, 85, 243, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_data[3],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
                tooltip: { show: false },
              },
            ],
          },
          //最外层圈
          {
            name: this.echarts_name[4],
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ['170%', '184%'], //[外宽(越大越宽),内柱状图的宽度(越大越细小)]
            center: ['48%', '105%'],

            tooltip: {
              // show: this.echarts_name[4],
              formatter: (params) => {
                // console.log(params);
                if (params.seriesName.length > 30) {
                  params.seriesName = params.seriesName.slice(0, 30) + '...';
                  // console.log('名字过长');
                }
                var res =
                  '<p>' +
                  this.$t(`message.itemAnalyse.productName`) +
                  `${params.seriesName}` +
                  '&nbsp&nbsp&nbsp&nbsp' +
                  this.$t(`message.itemAnalyse.Values`) +
                  `${params.value}` +
                  '</p>' +
                  this.$t(`message.itemAnalyse.TargetConsumers`) +
                  this.sement_sum2[3];
                return res;
              },
            },
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_data[4],
                itemStyle: {
                  normal: {
                    color: 'rgba(236, 146, 81, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_data[4],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
                tooltip: { show: false },
              },
            ],
          },
        ],
      };

      myChart.setOption(option, true);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // 空彩虹图
    drawCharts() {
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById('main_empty'));
      // 指定图表的配置项和数据

      let option = {
        backgroundColor: '#fff',
        title: {
          text: '',
          textStyle: {
            color: '#000',
            fontSize: 32,
            fontWeight: 'bold',
          },

          left: 'center',
          top: 100,
          bottom: '69%',
          itemGap: 60,
        },
        tooltip: {
          show: false,
        },
        color: ['#01dadc', '#23cea7', '#5096e0'],
        legend: {
          orient: 'vertical',
          x: 690,
          y: 120,
          // data: ["危急", "中等", "严重", "一般"],
        },
        series: [
          // 第二层
          {
            name: 'Ideal 1',
            type: 'pie',
            //起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            tooltip: {},
            radius: ['110%', '124%'],
            center: ['48%', '105%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                color: '#fff',
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                // this.echarts_index[1]
                value: this.echarts_index[1],
                itemStyle: {
                  normal: {
                    color: 'rgba(227, 44, 132, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_index[1],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
            ],
          },
          // 第一层
          {
            name: this.formInlines.product,
            type: 'pie',
            //起始刻度的角度，默认为 90 度，即圆心的正上方。0 度为圆心的正右方。
            startAngle: 0,
            hoverAnimation: false,
            tooltip: {},
            radius: ['90%', '104%'],
            center: ['48%', '105%'],
            label: {
              normal: {
                show: false,
                position: 'center',
                color: '#fff',
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                // 满值的颜色
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                // 效果值的颜色
                value: this.echarts_index[0],
                itemStyle: {
                  normal: {
                    color: 'rgba(29, 92, 166, 1)',
                  },
                },
              },
              {
                // 多于值的颜色
                value: 5 - this.echarts_index[0],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
            ],
          },
          // 第三层
          {
            name: 'Ideal 2',
            type: 'pie',
            startAngle: 0,
            radius: ['130%', '144%'],
            center: ['48%', '105%'],
            legendHoverLink: false,
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '30',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_index[2],
                itemStyle: {
                  normal: {
                    color: 'rgba(0, 183, 175,1)',
                  },
                },
              },
              {
                // 2
                value: 5 - this.echarts_index[2],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
            ],
          },
          // 第四层
          {
            name: 'Ideal 3',
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ['150%', '164%'],
            center: ['48%', '105%'],
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_index[3],
                itemStyle: {
                  normal: {
                    color: 'rgba(98, 85, 243, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_index[3],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
            ],
          },
          //最外层圈
          {
            name: 'Prototype 4',
            type: 'pie',
            startAngle: 0,
            hoverAnimation: false,
            radius: ['170%', '184%'], //[外宽(越大越宽),内柱状图的宽度(越大越细小)]
            center: ['48%', '105%'],
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '10',
                  fontWeight: 'bold',
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              {
                value: 5,
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
              {
                value: this.echarts_index[4],
                itemStyle: {
                  normal: {
                    color: 'rgba(236, 146, 81, 1)',
                  },
                },
              },
              {
                value: 5 - this.echarts_index[4],
                itemStyle: {
                  normal: {
                    color: 'rgba(235, 245, 254, 1)',
                  },
                },
              },
            ],
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // 勾选逻辑
    getPanel(i, item) {
      this.historymaeket = !this.historymaeket;
      if (i === 0 || i === 1 || i === 2) {
        console.log('不通过');
        console.log('i的值', i, item);
      } else {
        console.log('i的值', i, item);
        //注释bug修复
        // this.history.forEach((item, index) => {
        //   if (this.history[i - 3].checked == '' || !this.history[i - 3].checked) {
        //     console.log('  this.history[i - 3] :>> ', this.history[i - 3]);
        //     this.history[i - 3].checked = true;
        //     this.$set(this.history_click, i, true);

        //     // ! 4个  最大限制选择四个，如果多处不做判断
        //     let num = 0;
        //     this.history_click.forEach((item) => {
        //       if (item == true) {
        //         num++;
        //       }
        //     });
        //     if (num == 5) {
        //       // console.log('进入判断判断超出num :>> ');
        //       this.$set(this.history_click, i, false);
        //       this.history[i - 3].checked = false;
        //       this.history[i - 3].colorsindex = 0;
        //     }
        //   } else if (this.history[i - 3].checked) {
        //     // 如果是true 已经勾选状态 , 那么就 取消勾选/设置为false/样式恢复默认

        //     this.history[i - 3].checked = false;
        //     this.$set(this.history_click, i, false);
        //     this.history[i - 3].colorsindex = 0;
        //     console.log('this.history[i - 3] ', this.history[i - 3]);
        //   }
        // });
      }

      if (this.click_class.indexOf(i) == -1 && this.click_class.every((element) => element !== '')) {
        console.log(',已经满四个了，并且不是已经勾选过的数据');
        message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.manyProduct`),
          type: 'warning',
          customClass: 'change_bar',
        });
        return;
      }
      console.log('没有满组条件，进入判断');

      // 判断是否取消没有重要度的数据数据

      this.$nextTick(() => {
        const arr = this.history_click.slice(0, 3);
        if (arr.includes(true)) {
          this.map_arrs = this.map_arrs.filter((item) => item.importtant !== 5);
          console.log(this.map_arrs);
          console.log('取消没有重要度的数据');
        } else {
          console.log('展示全部数据');
          this.map_arrs = this.map_arrss;
        }
      });
      // // 判断checked的状态
      // console.log("111");
      let a = 0;
      //已经勾选的数据 彩虹图/雷达图删除数据
      console.log('this.click_class,this.history_click :>> ', this.click_class, this.history_click);
      for (let index = 0; index < this.click_class.length; index++) {
        // 如果 已经勾选了就 取消勾选
        if (this.click_class[index] === i) {
          // 初始化数据
          this.history_click[i] = false;
          this.$set(this.history_click, i, false);

          this.$set(this.click_class, index, '');
          if (i < 3) {
            this.recommend[i].checked = false;
            this.recommend[i].colorsindex = 0;
          } else {
            this.history[i - 3].checked = false;
            this.history[i - 3].colorsindex = 0;
          }

          // 彩虹图删除数据
          this.$set(this.echarts_data, index + 1, 0);
          this.$set(this.echarts_name, index + 1, '');
          //柱状图的数据
          this.$set(this.bar_name, index + 1, '');

          // 删除感官属性数据
          for (let x = 0; x < this.selectarr.length; x++) {
            if (item.product_name == this.selectarr[x].product_name) {
              this.selectarr.splice(x, 1);
            }
          }

          a = 1;
          this.drawChart();
          this.drawChart_bar();
          // return;
        }
      }
      console.log('this.click_class,this.history_click :>> ', this.click_class, this.history_click);

      if (a == 1) {
        return;
      }
      // 勾选上
      for (let index = 0; index < this.click_class.length; index++) {
        if (this.click_class[index] === '') {
          // item.checked = true;
          this.$set(this.history_click, i, true);
          if (i < 3) {
            this.recommend[i].cheched = true;
          } else {
            this.history[i - 3].checked = true;
          }
          this.$set(this.echarts_data, index + 1, item.overall_data.toFixed(2));
          this.$set(this.echarts_name, index + 1, item.product_name);
          //名字截断
          this.$set(this.bar_name, index + 1, item.product_name);
          const pattern = /[\u4e00-\u9fa5]/; // 正则表达式匹配汉字的范围
          let ascii = pattern.test(this.bar_name[index + 1]);

          if (ascii && this.bar_name[index + 1].length > 3) {
            let name = this.bar_name[index + 1].substring(0, 3) + '.......';
            // console.log(name);
            this.$set(this.bar_name, index + 1, name);
          }

          // console.log('柱状图的截取名称', this.bar_name);
          // console.log(item.product_name.length);
          // 感官属性展示数据
          // console.log(item.paneldata);
          // ! item就是全部的值
          // console.log('展示感官属性的数据', item);

          //新增数据处理 paneldata 排序
          // !!!!!!!!!!排序 ！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
          // !!!!!!!!!!排序 ！
          // !!!!!!!!!!排序 ！
          // !!!!!!!!!!排序 ！
          let logic1 = [];
          // console.log('新增数据处理 paneldata 排序', logic1);
          let ppp = JSON.parse(JSON.stringify(item.paneldata));
          for (let index in ppp) {
            logic1.push({
              importtant: this.attribute_data.panel_attributes_weight[index],
              data: ppp[index],
              name: this.attribute_data.panel_attributes_item[index],
            });
          }

          // console.log('pop数据', ppp);
          // console.log(logic1[index].importtant);
          let logic2 = [];
          //! 按照 重要度 排序  先把重要度等级排序一下
          for (let index = 0; index < logic1.length; index++) {
            if (logic1[index].importtant >= 8) {
              logic2.push(0);
            } else if (logic1[index].importtant >= 5 && logic1[index].importtant < 8) {
              logic2.push(1);
            } else if (logic1[index].importtant >= 3 && logic1[index].importtant < 5) {
              logic2.push(2);
            } else if (logic1[index].importtant >= 1 && logic1[index].importtant < 3) {
              logic2.push(3);
            } else if (logic1[index].importtant >= 0 && logic1[index].importtant < 1) {
              logic2.push(4);
            } else if (logic1[index].importtant >= -1 && logic1[index].importtant < 0) {
              logic2.push(5);
            }
          }
          // console.log('排序后的重要度分区', logic2);
          //! 根据重要度的值，把对应数据放进去
          let logic = [];
          let ppp1 = JSON.parse(JSON.stringify(item.paneldata));
          for (let index in logic2) {
            logic.push({
              important: this.attribute_view[index],
              data: ppp1[index],
              name: this.attribute_data.panel_attributes_item[index],
              importtantdata: this.attribute_data.panel_attributes_weight[index],
            });
          }
          // console.log('根据重要度排序，里面有对应数据，重要度，重要度分级，名字', logic);

          // ! 先根据重要度进行排序，如果值一样就根据里面的重要度指标在进行排序，最后赋值
          for (let i = 0; i < logic.length; i++) {
            for (let j = 0; j < logic.length - i - 1; j++) {
              if (logic[j].important > logic[j + 1].important) {
                const temp = logic[j];
                logic[j] = logic[j + 1];
                logic[j + 1] = temp;
              } else if (logic[j].importtant == logic[j + 1].importtant) {
                if (logic[j].importtantdata < logic[j + 1].importtantdata) {
                  const temp = logic[j];
                  logic[j] = logic[j + 1];
                  logic[j + 1] = temp;
                }
              }
            }
          }
          // console.log('最后排序的数据列表', logic);

          //排序结束
          //给item的paneldata赋值；

          var set = [];
          for (let k = 0; k < logic.length; k++) {
            set.push(logic[k].data);
          }
          // console.log(set);
          item.sort_panel = set;
          // console.log(item);
          //此时的数据已经替换成
          //新增数据处理 结束节点
          this.selectarr.splice(index, 0, item);
          this.selectarr[index].colorsindex = index + 1;
          this.colorsindex = index;
          this.$set(this.click_class, index, i);
          // console.log('新增');
          this.drawChart();
          this.drawChart_bar();
          // console.log(this.echarts_data);
          // console.log(this.echarts_name);
          return;
        }
      }

      // 如果已经选中四个了之后
      if (this.click_class.every((element) => element !== '')) {
        console.log('已经满足四个了 :>> ');
        this.history[i].checked = false;
        this.$set(this.history, this.history[i].checked, false);
        this.history[i].colorsindex = 0;
        message({
          showClose: true,
          message: this.$t(`message.itemAnalyse.manyProduct`),
          type: 'warning',
          customClass: 'change_bar',
        });
      }

      if (this.click_class.length == 0) {
        this.selectarr = [];
      }
      console.log(this.history_click, 'history_click');
      console.log('item的值');
    },
    getChecked(i, item) {
      console.log('222');
      let a = 0;
      for (let index = 0; index < this.click_class.length; index++) {
        if (this.click_class[index] === i) {
          item.checked = false;
          this.$set(this.click_class, index, '');
          // 彩虹图删除数据
          this.$set(this.echarts_data, index + 1, 0);
          this.$set(this.echarts_name, index + 1, '');
          // 删除感官属性数据
          this.selectarr.splice(index, 1);
          console.log(this.click_class);
          console.log('取消');
          a = 1;
          this.drawChart();
          this.drawChart_bar();
          return;
        }
      }
      if (a == 1) {
        return;
      }

      for (let index = 0; index < this.click_class.length; index++) {
        if (this.click_class[index] === '') {
          item.checked = true;
          // 彩虹图增加数据
          this.$set(this.echarts_data, index + 1, Number(item.overall_data).toFixed(2));
          this.$set(this.echarts_name, index + 1, item.product_name);
          // 感官属性展示数据
          // this.select_arrs.splice(index, 1, item);
          this.selectarr.splice(index, 1, item);
          this.selectarr[index].colorsindex = index + 1;
          this.colorsindex = index;
          this.$set(this.click_class, index, i);
          console.log('新增');
          console.log(this.click_class);
          // 新增数据
          console.log(item);
          console.log(this.echarts_data);
          console.log(this.echarts_name);
          console.log(this.selectarr);
          this.drawChart();
          this.drawChart_bar();
          return;
        }
      }
    },
    // 跳转路由 对比分析
    goItem() {
      console.log(this.formInline.product, '项目');
      console.log(this.formInlines.product, '产品');
      this.$router.push({
        name: 'contrast',
        params: {
          project_name: this.formInline.product,
          product_name: this.formInlines.product,
          program_id: this.project_id,
        },
      });
    },

    // 选择单品分析,增加到最近打开项目
    async req_addyc() {
      await HttpClient.post('result/add_withoutalg', {}).then((res) => {
        console.log(res);
      });
    },
    // 重置选择对比产品
    clear_choose() {
      for (let index = 0; index < this.history_click.length; index++) {
        this.history_click[index] = false;
      }
      this.history.forEach((item, index) => {
        this.history[index].checked = false;
        this.history[index].colorindex = 0;
        this.history[index].colorsindex = 0;
      });

      this.click_class = ['', '', '', ''];
      this.echarts_data.splice(1, 4, '0', '0', '0', '0');
      this.echarts_name.splice(1, 4, '', '', '', '');
      this.bar_name.splice(1, 4, '', '', '', '');
      this.selectarr = [];
      this.drawChart();
      this.drawChart_bar();
      // this.colorsindex = -1;
      console.log(this.selectarr);
      console.log('我重置了');
    },

    // 初始化页面时请求 用户的项目信息
    async req_UserProgram() {
      await HttpClient.post('program/programlist', {
        sub: this.$store.state.userid,
      }).then((res) => {
        this.program_name = res.list;
        this.program_name.filter((item) => {
          if (item.category === '防晒霜') {
            item.category = 'Sun care';
          }
        });
        console.log(res);
      });

      console.log(this.program_name);
      this.req_UserProduct();
    },

    // 请求 项目对应的产品信息
    async req_UserProduct() {
      // 输出 选中的项目名字
      console.log(this.choose_pj_name);

      // 寻找id
      for (let index = 0; index < this.program_name.length; index++) {
        if (this.choose_pj_name == this.program_name[index].project_name) {
          //对比名字,拿到项目id
          this.project_id = this.program_name[index]._id;
        }
      }

      // 拿到项目id去请求
      await HttpClient.post('ctmproduct/productlist', {
        program: this.project_id, //项目id
      }).then((res) => {
        console.log(res);
        // console.log(this.project_id);
        // 拿到产品数组 list
        this.product_name = res.list;
      });
      console.log(this.product_name);
      console.log(this.choose_product);
      if (this.choose_product != '') {
        console.log(this.choose_product);
        // this.req_Product();
      }
      // this.req_suanfa();
    },
    // 把选中项目下的产品导入数据
    async req_suanfa() {
      console.log(this.product_name);
      for (let index = 0; index < this.product_name.length; index++) {
        await HttpClient.post('result/find', {
          updates: {
            Product: this.product_name[index]._id, //产品id
          },
        }).then((res) => {
          console.log(res);
          console.log('123123123123');
          // if(res)
        });
      }
    },
    // 请求产品信息(循环调用查找结果)
    async req_Product(role) {
      console.log('点击开始预测');
      this.clear_choose();
      console.log(this.formInlines.product);

      if (this.formInlines.product == '' || this.formInlines.product == undefined) {
        message({
          showClose: true,
          message: this.$t(`message.Message.NoSelected`),
          type: 'warning',
          customClass: 'change_bar',
        });
      } else {
        this.loading = true;
        if (role != 'alg') {
          this.timer = setInterval(() => {
            // console.log('this.loadingProgress :>> ', this.loadingProgress);
            this.loadingProgress += 100 / 45;
            if (this.loadingProgress >= 100) {
              if (!this.analyzeStatus) message.warning('分析超时请重试!');
              clearInterval(this.timer);
              this.loadingProgress = 0;
              this.loading = false;
              this.timer = null;
            }
          }, 1000);
        }

        for (let index = 0; index < this.product_name.length; index++) {
          if (this.choose_product == this.product_name[index].name) {
            this.product_id = this.product_name[index]._id;
          }
        }
        // 拿到产品id
        // console.log(this.product_id);
        // 增加分析次数
        this.Anay.push(1);
        await HttpClient.post('result/find', {
          Product: this.product_id, //产品id
          segment: this.segment,
        }).then((res) => {
          console.log(res);

          if (this.Anay[this.Anay.length - 1] == 1) {
            this.wait_1 = res;

            console.log(this.wait_1);
            if (this.wait_1.status == 0) {
              this.req_Product4();

              // console.log('我执行了44444');
            } else if (this.wait_1.status == 1) {
              //此产品未分析
              this.analyzeStatus = false;
              // alert('没有数据，请分析。');
              // console.log('我执行了33333');
              this.req_Product3();
            } else if (this.wait_1.status == 2) {
              //没有产品数据，无法分析；
              this.loading = false;
              this.err_message = this.wait_1.message;
              this.err_messages();
            }
          }
        });
      }
    },

    // 请求产品  product info
    async req_Product2() {
      if (this.change_bar_num == 1) {
        this.caihong_ = 0;
        this.suanfa_empty = 5;
        this.suanfa_emptys = 5;
      } else {
        this.caihong_ = 1;
        this.suanfa_empty = 0;
      }
      // this.caihong_ = 1
      // this.suanfa_emptys = 0;
      // ! 判断如果是 Sun care 转防晒霜
      if (this.choose_xm_name == 'Sun care') {
        this.choose_xm_name = '防晒霜';
      }

      // !拿到数据的接口
      console.log(this.product_id);
      await HttpClient.post('ctmproduct/productinfo', {
        product_id: this.product_id,
        category: this.choose_xm_name,
      }).then((res) => {
        console.log(res);
        if (this.Anay[this.Anay.length - 1]) {
          this.product_message = res;
          this.overall95_1 = this.product_message.overall_95[0];
          this.overall95_2 = this.product_message.overall_95[1];
          this.overall90_1 = this.product_message.overall_90[0];
          this.overall90_2 = this.product_message.overall_90[1];
          this.comprehensive_name = res.comprehensive_name_en;
          this.features_name = res.features_name_en;
        }
        console.log(this.product_message);

        // }, 500);
      });
      console.log(this.product_message);

      this.$set(this.echarts_data, 0, Number(this.product_message.overall_data).toFixed(2));
      this.$set(this.echarts_name, 0, this.choose_product);

      // if (this.choose_product.length > 8) {
      //   let name_ = this.choose_product.substr(0, 8) + "...";
      //   this.$set(this.bar_name, 0, name_);
      // } else {
      //   this.$set(this.bar_name, 0, this.choose_product);
      // }
      this.$set(this.bar_name, 0, this.choose_product);
      //汉字截断
      let ascii = this.bar_name[0].charAt(0);
      console.log(ascii);
      console.log(ascii.charCodeAt());
      if (ascii.charCodeAt() > 128) {
        console.log('我是汉字');
        let name = this.bar_name[0].substr(0, 3) + '...';
        console.log(name);
        this.$set(this.bar_name, 0, name);
      }

      console.log(this.echarts_data);
      console.log(this.echarts_name);
      this.req_History();
      setTimeout(() => {
        this.drawChart();
        this.drawChart_bar();
      }, 100);
      this.req_Recommend();
      this.req_History();
      this.req_Attribute();
    },
    //算法接口(没有分析过走这个)
    async req_Product3() {
      console.log(this.project_id);
      console.log('this.segement :>> ', this.segment);
      HttpClient.post('task/alg_exe_new', {
        account_id: this.$store.state.userid,
        id: this.project_id,
        segment: this.segment,
      }).then((res) => {
        console.log(res, '市场数据是否大于100');
        // if (res.message != '' && res.message == '小于100' && res.status == 2) {
        //   // this.loading = false;
        //   // this.loadingProgress = 0;
        //   // clearTimeout(this.timer);
        //   console.log('object :>> ', 'object当前选择的细分市场中数据库信息较少，仅供参考');
        //   message({
        //     showClose: true,
        //     message: '当前选择的细分市场中数据库信息较少，仅供参考',
        //     type: 'warning',
        //     customClass: 'change_bar',
        //   });
        //   // console.log('算法细分市场错误');
        //   // this.analyzeStatus = true;
        //   // this.req_Product('alg');
        //   // return;
        // }
        // this.loading = true;
        // if (res.message == 'success') {
        if (this.Anay[this.Anay.length - 1]) {
          this.analyzeStatus = true;
          this.req_Product('alg');
        }

        // }
      });
    },
    //分析分析过走这个)
    async req_Product4() {
      let that = this;
      console.log(this.project_id);
      console.log('this.segement :>> ', this.segment);
      HttpClient.post('task/alg_exe_new', {
        account_id: this.$store.state.userid,
        id: this.project_id,
        segment: this.segment,
      }).then((res) => {
        if (this.Anay[this.Anay.length - 1]) {
          console.log(res, '市场数据是否大于100');
          that.loadingProgress = 100;
          // 替换tooltip
          if (this.$store.state.lang === 'cn') {
            that.sement_sum_show = that.sement_sum[0][0];
          } else {
            that.sement_sum_show = that.sement_sum[0][1];
          }

          if (res.message != '' && res.message == '小于100' && res.status == 2) {
            // this.loading = false;
            // this.loadingProgress = 0;
            // clearTimeout(this.timer);
            console.log('object :>> ', 'object当------------2222');
            console.log(res.message);

            // console.log('算法细分市场错误');
            // this.analyzeStatus = true;
            // this.req_Product('alg');
            // return;
          }
          this.req_Product2();
          // ! 需求修改 弹框页面

          if (this.onecode.length > 0 && this.onecode[0] !== '无' && this.onecode[0] !== 'NA') {
            console.log('走里面有数据');
            setTimeout(() => {
              this.showSexLoding = true;
            }, 400);
          }
        }
      });

      return;
    },
    // 请求 推荐优化方案的数据
    // ! 合并
    async req_Recommend() {
      console.log(this.project_id);
      console.log(this.product_id);
      await HttpClient.post('ctmproduct/idealinfo', {
        id: this.product_id,
        category: this.choose_xm_name,
      }).then((res) => {
        // console.log(res, '??????????????????????????????res');
        this.recommend = res;
        //   this.computedmaindata()
        //      this.attribute_view = [];
        //   // !  获取重要度数据，根据值进行排序。进行存储
        //   for (let index = 0; index < this.dataMain.length; index++) {
        //     if (this.dataMain[index].importtant >= 8) {
        //       this.attribute_view.push(0);
        //     } else if (this.dataMain[index].importtant >= 5 && this.dataMain[index].importtant < 8) {
        //       this.attribute_view.push(1);
        //     } else if (this.dataMain[index].importtant >= 3 && this.dataMain[index].importtant < 5) {
        //       this.attribute_view.push(2);
        //     } else if (this.dataMain[index].importtant >= 1 && this.dataMain[index].importtant < 3) {
        //       this.attribute_view.push(3);
        //     } else if (this.dataMain[index].importtant >= 0 && this.dataMain[index].importtant < 1) {
        //       this.attribute_view.push(4);
        //     }else if (this.dataMain[index].importtant >= -1 && this.dataMain[index].importtant < 0) {
        //       this.attribute_view.push(5);
        //     }
        //   }
        //   console.log("下面是根据重要度，排序",this.attribute_view);
        // });
        // // !!  一份值
        // this.map_arr = [];
        // for (let index in this.attribute_view) {
        //   this.map_arr.push({
        //     importtant: this.attribute_view[index],
        //     data: this.wait_1.result.panel_attributes_data[index],
        //     name: this.attribute_data.panel_attributes_item[index],
        //     importantdata:this.dataMain[index].importantdata,
        //   });
        // }

        this.tj_paln_name[0].title = this.$store.state.lang == 'cn' ? res[0].optimization[0] : res[0].optimization_en[0];
        this.tj_paln_name[1].title = this.$store.state.lang == 'cn' ? res[0].optimization[1] : res[0].optimization_en[1];
        this.tj_paln_name[2].title = this.$store.state.lang == 'cn' ? res[0].optimization[2] : res[0].optimization_en[2];
        console.log(this.recommend.length);
        for (let index = 0; index < this.recommend.length; index++) {
          // this.recommend[index].overall_data =
          //   this.recommend[index].overall_data.toFixed(2);
          this.recommend[index] = Object.assign(this.recommend[index], this.add_checked);
        }

        if (this.$store.state.lang === 'cn') {
          this.$set(this.recommend[0], 'product_name', '优化方案1');
          this.$set(this.recommend[1], 'product_name', '优化方案2');
          this.$set(this.recommend[2], 'product_name', '优化方案3');
        } else if (this.$store.state.lang === 'en') {
          this.$set(this.recommend[0], 'product_name', 'optimal1');
          this.$set(this.recommend[1], 'product_name', 'optimal2');
          this.$set(this.recommend[2], 'product_name', 'optimal3');
        }
        console.log(this.recommend, 'this.recommend');
      });
    },
    //文件中没有新建产品的 数据（无法分析的提示）
    err_messages() {
      message({
        showClose: true,
        message: this.err_message,
        type: 'warning',
        customClass: 'change_bar',
      });
    },

    // 请求历史推荐方案
    async req_History() {
      console.log('请求历史数据推荐方案');
      console.log(this.product_id);
      await HttpClient.post('ctmproduct/historyinfo', {
        user_id: this.$store.state.userid,
        product_id: this.product_id,
      }).then((res) => {
        // if (this.$store.state.lang == 'en') {
        //   this.history_name = 'All';
        // } else {
        //   this.history_name = '全部';
        // }

        console.log(res);
        this.history = res;
        console.log(this.history, 'history');
        this.history_click = [];
        for (let a = 0; a <= this.history.length; a++) {
          this.history_click.push(false);
        }
        console.log(this.history_click, 'history');
        for (let index = 0; index < this.history.length; index++) {
          this.history[index] = Object.assign(this.history[index], this.add_checked);
          this.oldHis[index] = Object.assign(this.history[index], this.add_checked);
        }
      });
    },
    //请求历史推荐方案（排序版）
    async sort() {
      // ! 对现有数，进行时间排序
      this.historymaeket = !this.historymaeket;
      console.dir(this.history);
      if (!this.historymaeket) {
        this.history.sort((a, b) => new Date(a.createdtime).getTime() - new Date(b.createdtime).getTime());
      } else {
        this.history.sort((b, a) => new Date(a.createdtime).getTime() - new Date(b.createdtime).getTime());
      }
      // // this.clear_choose();
      // // let sort_arr = false;
      // if (this.sort_arr == false) {
      //   this.sort_arr = true;
      // } else {
      //   this.sort_arr = false;
      // }
      // console.log(this.sort_arr);
      // console.log(this.product_id);
      // await HttpClient.post('ctmproduct/historyinfo', {
      //   user_id: this.$store.state.userid,
      //   product_id: this.product_id,
      //   upSort: this.sort_arr,
      // }).then((res) => {
      //   console.log(res);
      //   this.history = res;
      //   console.log(this.history, 'history');
      //   this.history_click = [];
      //   for (let a = 0; a <= this.history.length; a++) {
      //     this.history_click.push(false);
      //   }
      //   console.log(this.history_click, 'history');
      //   for (let index = 0; index < this.history.length; index++) {
      //     this.history[index] = Object.assign(this.history[index], this.add_checked);
      //     this.oldHis[index] = Object.assign(this.history[index], this.add_checked);
      //   }
      // });
      // //note点击创建日期排序,之前勾选的状态;
      // //历史数据记住勾选
      // for (let x = 0; x < this.choose_products.length; x++) {
      //   for (let y = 0; y < this.history.length; y++) {
      //     if (this.choose_products[x] == this.history[y].id) {
      //       console.log('我的位置在:', y);
      //       this.$set(this.history_click, y + 3, true);
      //     }
      //   }
      // }
      // //推荐产品勾选
      // for (let x = 0; x < this.choose_products.length; x++) {
      //   for (let y = 0; y < this.recommend.length; y++) {
      //     if (this.choose_products[x] == this.recommend[y].id) {
      //       console.log('我的位置在:', y);
      //       this.$set(this.history_click, y, true);
      //     }
      //   }
      // }
    },
    //FIXME 获取感官数据维度和重要性
    async req_Attribute() {
      console.log('请求了 重要度');
      await HttpClient.post('attribute/panel_info').then((res) => {
        console.log(res);
        this.attribute_data = res;
        this.computedmaindata();
        // ! 下面是根据重要度，排序
        this.attribute_view = [];
        // !  获取重要度数据，根据值进行排序。进行存储
        for (let index = 0; index < this.dataMain.length; index++) {
          if (this.dataMain[index].importtant >= 8) {
            this.attribute_view.push(0);
          } else if (this.dataMain[index].importtant >= 5 && this.dataMain[index].importtant < 8) {
            this.attribute_view.push(1);
          } else if (this.dataMain[index].importtant >= 3 && this.dataMain[index].importtant < 5) {
            this.attribute_view.push(2);
          } else if (this.dataMain[index].importtant >= 1 && this.dataMain[index].importtant < 3) {
            this.attribute_view.push(3);
          } else if (this.dataMain[index].importtant >= 0 && this.dataMain[index].importtant < 1) {
            this.attribute_view.push(4);
          } else if (this.dataMain[index].importtant >= -1 && this.dataMain[index].importtant < 0) {
            this.attribute_view.push(5);
          }
        }
        console.log('下面是根据重要度，排序', this.attribute_view);
      });
      // !!  一份值
      this.map_arr = [];
      for (let index in this.attribute_view) {
        this.map_arr.push({
          importtant: this.attribute_view[index],
          data: this.wait_1.result.panel_attributes_data[index],
          ch_name: this.attribute_data.panel_attributes_item[index],
          en_name: this.attribute_data.panel_attributes_item_en[index],
          importantdata: this.dataMain[index].importantdata,
        });
      }
      // ! 一份值
      // this.maps = [];
      // for (let index in this.attribute_view) {
      //   this.maps.push({
      //       importtant: this.attribute_view[index],
      //     data: this.wait_1.result.panel_attributes_data[index],
      //     name: this.attribute_data.panel_attributes_item[index]
      //   });
      // }

      this.maps = this.map_arr;

      for (let i = 0; i < this.map_arr.length; i++) {
        for (let j = 0; j < this.map_arr.length - i - 1; j++) {
          if (this.map_arr[j].importtant > this.map_arr[j + 1].importtant) {
            const temp = this.map_arr[j];
            this.map_arr[j] = this.map_arr[j + 1];
            this.map_arr[j + 1] = temp;
          } else if (this.map_arr[j].importtant == this.map_arr[j + 1].importtant) {
            if (this.map_arr[j].importantdata < this.map_arr[j + 1].importantdata) {
              const temp = this.map_arr[j];
              this.map_arr[j] = this.map_arr[j + 1];
              this.map_arr[j + 1] = temp;
            }
          }
        }
      }

      this.map_arrss = this.map_arr;

      // // ----------------maps=--------------------
      this.map_arrs = JSON.parse(JSON.stringify(this.map_arr));
      this.map_arrss = JSON.parse(JSON.stringify(this.map_arr));

      this.map_arrs = JSON.parse(JSON.stringify(this.maps));
      this.map_arrss = JSON.parse(JSON.stringify(this.maps));
      // 中英文切换
      for (let index = 0; index < this.maps.length; index++) {
        this.options_search.push({
          label: this.maps[index].en_name,
          value: index,
        });
        // console.log('this.map_arr[index].name :>> ', this.map_arr[index].name);
      }
      for (let index = 0; index < this.maps.length; index++) {
        this.ch_options_search.push({
          label: this.maps[index].ch_name,
          value: index,
        });
      }
    },
    // 多选框方法
    //FIXME 多选框方法 筛选
    handleChange(e) {
      console.log('e :>> ', e);
      // this.map_arrs = JSON.parse(JSON.stringify(this.map_arr));
      this.map_arrs = JSON.parse(JSON.stringify(this.maps));
      this.map_arrss = JSON.parse(JSON.stringify(this.maps));
      let choose_s = [];
      for (let i = 0; i < this.choose_name.length; i++) {
        // console.log('this.choose_name :>> ', this.map_arrs[this.choose_name[i][0]].name);
        choose_s.push(this.map_arrs[this.choose_name[i][0]]);
      }
      // console.log('choose_s :>> ', choose_s);
      // console.log('我是最新的筛选数据', choose_s);
      this.map_arrs = choose_s;
    },
    //FIXME 重置
    reser_() {
      this.choose_name = [];
      // this.map_arrs = JSON.parse(JSON.stringify(this.map_arr));
      this.map_arrs = JSON.parse(JSON.stringify(this.maps));
    },

    red() {
      console.log(this.trans_name);
    },

    // 清除选中的节点
    clear_chooses() {
      let obj = {};
      obj.stopPropagation = () => {};
      try {
        this.$refs.market_cascader.clearValue(obj);
        this.onecode = 0;
      } catch (err) {
        this.$refs.market_cascader.handleClear(obj);
        this.onecode = [];
      }
      this.sexnum == 0;
      // console.log("123");
    },

    //请求 细分市场数据
    async req_market() {
      await HttpClient.post('bazaarInfo/getOptions', {
        language: this.$store.state.lang,
      }).then((res) => {
        console.log(res, 'res>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', res.option_cn, res.options_en);
        this.options = this.$store.state.lang == 'en' ? res.options_en : res.option_cn;
        this.options_en = res.options_en;
        this.options_cn = res.option_cn;
        // !如果role 不是1就不显示
      });
    },
    // ! 全部的数据 还没有进行排序
    computedmaindata() {
      if (this.wait_1 && this.attribute_data.panel_attributes_item) {
        const { panel_attributes_item, panel_attributes_weight } = this.attribute_data;
        const { panel_attributes_data } = this.wait_1.result;
        let mergedArray = panel_attributes_item.map((value, index) => {
          return {
            id: index + 1,
            value,
            importtant: panel_attributes_weight[index],
            data: panel_attributes_data[index],
            importantdata: panel_attributes_weight[index],
          };
        });
        this.dataMain = mergedArray;
      } else {
        return null;
      }
    },
    likeChange(e) {
      let str = e;
      if (str == 'ALL SPF') {
        console.log(e);
        this.showCut = 0;
      }
      if (str === 'SPF≥50') {
        this.showCut = 3;
        console.log('修改');
      }
      if (str === 'SPF<50') {
        this.showCut = 2;
        console.log('修改');
      }
      if (str === '物理防晒' || str == 'Chemical') {
        this.showCut = 4;
        console.log('修改');
      }
      if (str === '化学防晒' || str == 'Physical') {
        this.showCut = 5;
        console.log('修改');
      }
      if (str === 'Hybird' || str == '物化结合') {
        this.showCut = 6;
        console.log('修改');
      }
    },
    markethandleChange(val) {
      if (this.market.length > 0 && val.length > this.market.length) {
        let newIndex;
        let i = 0,
          j = 0;
        while (i < val.length && j < this.market.length) {
          if (val[i][0] === this.market[j][0] && val[i][1] === this.market[j][1]) {
            i++;
            j++;
          } else {
            //添加在中间的情况
            newIndex = i;
            break;
          }
        }
        //添加在末尾的情况
        if (j === this.market.length) {
          newIndex = i;
        }

        let delIndex = val.findIndex((item, index) => index !== newIndex && item[0] === val[newIndex][0]);
        if (delIndex >= 0) {
          // 取消选择的节点
          let cancelIndex;
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].value === val[delIndex][0]) {
              for (let j = 0; j < this.options[i].children.length; j++) {
                if (this.options[i].children[j].value === val[delIndex][1]) {
                  cancelIndex = j;
                  break;
                }
              }
              break;
            }
          }
          this.$nextTick(() => {
            let panelId = this.$refs.market_cascader.panel.$refs.menu[1].$el.id; //其中menu[1]表示右侧的面板 menu[0]即为左侧的面板
            let liId = document.getElementById(panelId + '-' + cancelIndex);
            liId.children[0].click();
          });
          val[delIndex] = '';
          val = val.filter((item) => item !== '');
        }
      }
      this.market = val;
    },

    throttledClick(i, item) {
      console.log(i, item);
      const now = Date.now();

      if (now - this.lastClickTime >= 1000) {
        this.getPanel(i, item);
        this.lastClickTime = now;
      }
    },
  },

  mounted() {
    // echarts绘制
    this.drawChart();
    this.drawCharts();
    this.drawChart_bar();
    this.drawChart_bar_empty();
    // this.page_id = this.$route.params.page_id;
    // console.log("page" + this.page_id);

    // 调用项目接口
    this.req_UserProgram();
    this.req_market();
    console.log(this.trans_name);
    console.log(this.formInline.product);
    console.log(this.formInlines.product);
    this.$EventBus.$emit('Toitem', 'toItem');
    if (this.$store.state.lang == 'en') {
      this.history_name = 'All';
    }
    // !初始化下拉框数据
    if (this.$store.state.lang === 'cn') {
      this.likeoptions = [
        {
          //下拉框的数据
          value: 'ALL SPF',
          label: 'ALL SPF',
        },
        {
          value: 'SPF≥50',
          label: 'SPF≥50',
          disabled: true,
        },
        {
          value: 'SPF<50',
          label: 'SPF<50',
        },
        {
          value: '物理防晒',
          label: '物理防晒',
        },
        {
          value: '化学防晒',
          label: '化学防晒',
        },
        {
          value: '物化结合',
          label: '物化结合',
        },
      ];
    } else {
      this.likeoptions = [
        {
          //下拉框的数据
          value: 'ALL SPF',
          label: 'ALL SPF',
        },
        {
          value: 'SPF≥50',
          label: 'SPF≥50',
          disabled: true,
        },
        {
          value: 'SPF<50',
          label: 'SPF<50',
        },
        {
          value: 'Chemical',
          label: 'Chemical',
        },
        {
          value: 'Physical',
          label: 'Physical',
        },
        {
          value: 'Hybird',
          label: 'Hybird',
        },
      ];
    }
  },

  created() {
    // this.formInline.product = this.$route.params.project_name;
    // this.$EventBus.$emit('Toitem', 'toItem');
    this.formInline.product = this.$route.params.project_name;
    console.log(this.$route.params.project_name);
    // this.$EventBus.$on("qqq", (data) => {
    //   console.log(data);
    //   this.trans_name = data
    //   console.log(this.trans_name);
    //   this.formInline.product = this.trans_name
    // })

    this.formInlines.product = this.$route.params.product_name;
    // this.formInlines.product = 123456;
    // console.log(this.$route.params.product_name);

    // console.log("create" + this.product_new);
    // this.formInlines.product = this.product_new[0].name;
    if (this.likeoptions) {
      this.likevalue = this.likeoptions[0].label;
    }
  },
  beforeDestroy() {
    this.$EventBus.$off('send_programs');
  },
};
</script>
<style lang="less">
div.tip_class {
  /* margin-right: -110px; */
  left: 550px !important;
}
.hide {
  .el-cascader-menu:first-of-type {
    .el-cascader-node {
      .el-checkbox {
        display: none;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import url('../../style/ItemAnalysis/itemanalysis.less');
</style>
